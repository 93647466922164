import { CurrencySchema } from "@/features/host-locations/domain/entities/schemas/CurrencySchema";
import { z } from "zod";

export const ProductSchema = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    group: z.string().nullable(),
    maxQuantity: z.number().nullable(),
    price: z.number(),
    currency: CurrencySchema,
});
export type TProductSchema = z.infer<typeof ProductSchema>;
