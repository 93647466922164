import { millisecondsToSeconds } from "@/common/utilities/date/millisecondsToSeconds";
import { hasValue } from "@/common/utilities/hasValue";
import { Filter } from "@/features/filtering/domain/entities/Filter";
import { parseAsTimezonelessDate } from "@/features/filtering/filters/date-time-filter/date-time-filter-parser";
import {
    TDateTimeFilterLocationLevelMetadataDataSchema,
    TDateTimeFilterWorkspaceGroupLevelMetadataDataSchema,
} from "@/features/filtering/filters/date-time-filter/date-time-filter-schemas";
import { dateTimeFilterId } from "@/features/filtering/filters/date-time-filter/dateTimeFilterId";
import {
    dateTimeFilterQueryEndKey,
    dateTimeFilterQueryStartKey,
} from "@/features/filtering/filters/date-time-filter/dateTimeFilterQueryKeys";
import DateTimeFilterToolbarChip from "@/features/filtering/filters/date-time-filter/DateTimeFilterToolbarChip";
import { TMinimalFilterableHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/MinimalFilterableHostLocationWithWorkspaceGroupsSchema";
import moment from "moment/moment";

export const dateTimeFilter: Filter<
    TDateTimeFilterLocationLevelMetadataDataSchema,
    TDateTimeFilterWorkspaceGroupLevelMetadataDataSchema,
    typeof dateTimeFilterId
> = {
    id: dateTimeFilterId,
    applyableComponent: <DateTimeFilterToolbarChip />,
    filterResourcesRemotely(queryParams: Record<string, string | undefined>): {
        queryParams: Record<string, string>;
    } {
        const result: Record<string, string> = {};

        const start = Object.keys(queryParams).includes(
            dateTimeFilterQueryStartKey
        )
            ? moment(Date.parse(queryParams[dateTimeFilterQueryStartKey]!))
            : null;
        const end = Object.keys(queryParams).includes(dateTimeFilterQueryEndKey)
            ? moment(Date.parse(queryParams[dateTimeFilterQueryEndKey]!))
            : null;

        if (start && end) {
            // Add the start and end to the query, but as a DD-MM-YYYY:HH:MM string
            result["availabilityStart"] = parseAsTimezonelessDate.serialize(
                start.toDate()
            );
            result["availabilityEnd"] = parseAsTimezonelessDate.serialize(
                end.toDate()
            );

            // TODO: make user controllable
            result["meetingSpansAvailability"] = "true";
        }

        return { queryParams: result };
    },
    filterLocally: {
        async filterWorkspaceGroup({
            workspaceGroup,
            queryParams,
        }: {
            workspaceGroup: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema["workspace_groups"][0];
            queryParams: Record<string, string>;
        }): Promise<{
            isMatch: boolean;
            filterMetadata: TDateTimeFilterWorkspaceGroupLevelMetadataDataSchema;
        }> {
            const queryKeys = Object.keys(queryParams);
            const start = queryKeys.includes(dateTimeFilterQueryStartKey)
                ? moment(Date.parse(queryParams[dateTimeFilterQueryStartKey]))
                : null;
            const end = queryKeys.includes(dateTimeFilterQueryEndKey)
                ? moment(Date.parse(queryParams[dateTimeFilterQueryEndKey]))
                : null;

            const filterMetadata = getDateTimeFilterMetadata(
                workspaceGroup,
                start?.toDate(),
                end?.toDate()
            );

            return {
                isMatch: filterMetadata.hasRoomOfferingWithAvailability,
                filterMetadata,
            };
        },
        filterLocation({
            workspaceGroupsWithMetadata,
            queryParams,
        }: {
            location: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema;
            workspaceGroupsWithMetadata: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema["workspace_groups"];
            queryParams: Record<string, string>;
        }): {
            isMatch: boolean;
            filterMetadata: TDateTimeFilterLocationLevelMetadataDataSchema;
        } {
            const start = Object.keys(queryParams).includes(
                dateTimeFilterQueryStartKey
            )
                ? new Date(queryParams[dateTimeFilterQueryStartKey])
                : undefined;
            const end = Object.keys(queryParams).includes(
                dateTimeFilterQueryEndKey
            )
                ? new Date(queryParams[dateTimeFilterQueryEndKey])
                : undefined;

            return {
                isMatch: workspaceGroupsWithMetadata.some(
                    (group) => group.isMatch
                ),
                filterMetadata: {
                    start: start,
                    end: end,
                    meetingDurationInS:
                        hasValue(start) && hasValue(end)
                            ? millisecondsToSeconds(
                                  end.getTime() - start.getTime()
                              )
                            : null,
                    hasDateTimeSet: hasValue(start) && hasValue(end),
                    hasRoomOfferingWithAvailability:
                        workspaceGroupsWithMetadata.some(
                            (group) => group.isMatch
                        ),
                },
            };
        },
    },
};

export const getDateTimeFilterMetadata = (
    workspaceGroup: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema["workspace_groups"][0],
    startDateTime?: Date,
    endDateTime?: Date
): TDateTimeFilterWorkspaceGroupLevelMetadataDataSchema => {
    const meetingDurationInS =
        startDateTime && endDateTime
            ? millisecondsToSeconds(
                  endDateTime.getTime() - startDateTime.getTime()
              )
            : null;

    let hasRoomOfferingWithAvailability = true;
    if (hasValue(startDateTime) && hasValue(endDateTime)) {
        hasRoomOfferingWithAvailability = workspaceGroup.workspaces.some(
            (space) =>
                space.availability.length === 1 &&
                new Date(space.availability[0].blockEnd).getTime() >=
                    new Date(space.availability[0].endDateTime).getTime() &&
                new Date(space.availability[0].blockStart).getTime() <=
                    new Date(space.availability[0].startDateTime).getTime()
        );
    }

    return {
        hasDateTimeSet: hasValue(startDateTime) && hasValue(endDateTime),
        hasRoomOfferingWithAvailability,
        start: startDateTime,
        end: endDateTime,
        meetingDurationInS,
    };
};
