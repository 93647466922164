import { mapQueryStateTypeKey } from "@/app/(main)/discover/[[...params]]/(components)/(map)/mapQueryStateKeys";
import {
    TWezooResourceTypeValues,
    WezooResourceTypeValues,
} from "@/features/resource/resource-types/shared/WezooResourceType";
import { parseAsArrayOf, parseAsString, useQueryState } from "nuqs";

export const useResourceTypeFilter = () => {
    const [resourceTypeQueryParams, setResourceTypeQueryParams] = useQueryState(
        mapQueryStateTypeKey,
        parseAsArrayOf(parseAsString)
    );

    const validResourceTypeQueryParams = (resourceTypeQueryParams?.filter((v) =>
        WezooResourceTypeValues.includes(v as any)
    ) || []) as TWezooResourceTypeValues[];

    return {
        active: validResourceTypeQueryParams,
        setActive: setResourceTypeQueryParams,
    };
};
