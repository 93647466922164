import { hasValue } from "@/common/utilities/hasValue";
import { IconButton } from "@/component-library/components/buttons/icon-button/IconButton";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import {
    numberSpinnerInputStyle,
    numberSpinnerInputWrapperStyle,
    numberSpinnerStyle,
} from "@/component-library/components/user-input/number-spinner/NumberSpinnerStyles.css";
import { singleRangeInputFieldStyle } from "@/component-library/components/user-input/range-input-fields/_SingleRangeInputField.css";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { clsx } from "clsx";
import React, {
    forwardRef,
    HTMLAttributes,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";

export interface NumberSpinnerHandle {
    updateValue: (value?: string) => void;
    value?: number;
}

interface NumberSpinnerProps
    extends Omit<HTMLAttributes<HTMLInputElement>, "min" | "max" | "step"> {
    onValueChange?: (value?: number) => void;
    defaultValue?: number;
    value?: number;
    min?: number;
    max?: number;
    step?: number;
    placeholder?: string;
    name: string;
    wrapperStyle?: React.CSSProperties;
}

const NumberSpinner = forwardRef<NumberSpinnerHandle, NumberSpinnerProps>(
    (
        {
            min,
            max,
            step,
            name,
            placeholder,
            className,
            style,
            defaultValue,
            value,
            onValueChange,
            wrapperStyle,
            onChange,
            ...restProps
        },
        refIn
    ) => {
        const [inputState, setInputState] = useState<string>(
            value?.toString() ?? defaultValue?.toString() ?? ""
        );

        const [finalValue, setFinalValue] = useState<number | null>(
            value ?? defaultValue ?? null
        );

        const getSteppedValueImmediate = useCallback(
            (newValue: string): number | null => {
                if (!hasValue(step)) {
                    return null;
                }
                return Math.round(parseFloat(newValue) / step) * step;
            },
            [step]
        );

        const updateValue = useCallback(
            (newValue?: string) => {
                // Try to parse the value as a number.
                const parsed = parseFloat(newValue ?? "");
                if (isNaN(parsed)) {
                    // If the value is not a number, reset the input state and call the
                    // onValueChange callback.
                    setInputState("");
                    setFinalValue(min ?? 0);
                    return;
                }

                // Cap the value to the min and max values.
                const capped = Math.min(
                    Math.max(parsed, min ?? -Infinity),
                    max ?? Infinity
                );
                // Update the input state and call the onValueChange callback.
                setInputState(
                    getSteppedValueImmediate(capped.toString())?.toString() ??
                        ""
                );
                setFinalValue(getSteppedValueImmediate(capped.toString()));
            },
            [getSteppedValueImmediate, max, min]
        );

        useImperativeHandle(
            refIn,
            () => ({
                updateValue,
            }),
            [updateValue]
        );

        useEffect(() => {
            // Delay propagation of the value to the parent so that the user
            // can write the full number before it is processed
            const timeout = setTimeout(() => {
                if (!hasValue(finalValue)) {
                    return;
                }

                onValueChange?.(finalValue);
            }, 500);

            return () => clearTimeout(timeout);
        }, [finalValue, inputState, onValueChange, value]);

        useEffect(() => {
            if (!hasValue(value) || value === parseFloat(inputState)) {
                return;
            }

            updateValue(value.toString());
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [updateValue, value]);

        return (
            <div
                className={clsx(singleRangeInputFieldStyle, numberSpinnerStyle)}
                style={wrapperStyle}
            >
                <IconButton
                    label={"Decrease value"}
                    variant="filledSmall"
                    onClick={() => {
                        const newValue = parseFloat(inputState);
                        if (isNaN(newValue)) {
                            updateValue((0).toString());
                            return;
                        }

                        updateValue(
                            getSteppedValueImmediate(
                                `${newValue - (step ?? 1)}`
                            )?.toString()
                        );
                    }}
                >
                    <FontAwesomeIcon icon={faMinus} />
                </IconButton>
                <div className={numberSpinnerInputWrapperStyle}>
                    <input
                        name={name}
                        className={`${numberSpinnerInputStyle} ${className}`}
                        style={{ ...style }}
                        type="number"
                        min={min}
                        max={max}
                        step={step}
                        placeholder={placeholder}
                        value={inputState}
                        {...restProps}
                        onInput={(event) => {
                            updateValue(event.currentTarget.value);
                            onChange?.(event);
                        }}
                        onBlur={(event) => {
                            updateValue(
                                `${getSteppedValueImmediate(event.currentTarget.value)}`
                            );
                        }}
                    />
                </div>
                <IconButton
                    label={"Increase value"}
                    variant="filledSmall"
                    onClick={() => {
                        const newValue = parseFloat(inputState);
                        if (isNaN(newValue)) {
                            updateValue((0).toString());
                            return;
                        }

                        updateValue(
                            getSteppedValueImmediate(
                                `${newValue + (step ?? 1)}`
                            )?.toString()
                        );
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </IconButton>
            </div>
        );
    }
);
NumberSpinner.displayName = "NumberSpinner";

export default NumberSpinner;
