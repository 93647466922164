import { Filter } from "@/features/filtering/domain/entities/Filter";
import ResourceTypeFilterAppBarComponent from "@/features/filtering/filters/resource-type-filter/ResourceTypeFilterAppBarComponent";
import { TResourceTypeFilterWorkspaceGroupLevelMetadataDataSchema } from "@/features/filtering/filters/resource-type-filter/resource-type-filter-schemas";
import { resourceTypeFilterId } from "@/features/filtering/filters/resource-type-filter/resourceTypeFilterId";
import { TMinimalFilterableHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/MinimalFilterableHostLocationWithWorkspaceGroupsSchema";

export const resourceTypeFilter: Filter<
    undefined,
    TResourceTypeFilterWorkspaceGroupLevelMetadataDataSchema,
    typeof resourceTypeFilterId
> = {
    id: resourceTypeFilterId,
    urlSyncedComponent: <ResourceTypeFilterAppBarComponent />,
    filterResourcesRemotely(queryParams) {
        // Parse the resource types from the query params.
        const types = (queryParams.type || "").split(",").filter(Boolean);
        return {
            queryParams: {
                ...(types.length > 0 ? { resourceType: types } : {}),
            },
        };
    },
    filterLocally: {
        async filterWorkspaceGroup({
            queryParams,
            workspaceGroup,
        }: {
            location: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema;
            workspaceGroup: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema["workspace_groups"][0];
            queryParams: Record<string, string>;
        }): Promise<{
            isMatch: boolean;
            filterMetadata: TResourceTypeFilterWorkspaceGroupLevelMetadataDataSchema;
        }> {
            const types = (queryParams.type || "").split(",").filter(Boolean);
            const isFilteredByResourceType = types.length > 0;
            const isMatch =
                !isFilteredByResourceType ||
                types.includes(workspaceGroup.type);
            return {
                isMatch,
                filterMetadata: {
                    isMatchingResourceType: isMatch,
                    isFilteredByResourceType,
                },
            };
        },
    },
};
