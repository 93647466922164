import { z } from "zod";

export const AddressSchema = z.object({
    address_line_1: z.string(),
    address_line_2: z.string().nullable(),
    postal_code: z.string(),
    city: z.string(),
    province: z.string(),
    country: z.string(),
});
export type TAddressSchema = z.infer<typeof AddressSchema>;
