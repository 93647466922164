import { NoValue } from "@/common/domain/entities/typing/NoValue";
import { hasValue } from "@/common/utilities/hasValue";
import { centToWhole } from "@/common/utilities/math/centToWhole";
import { TPricingCalculationSchema } from "@/features/host-locations/domain/entities/schemas/AvailabilitySchema";
import { TMinimalFilterableHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/MinimalFilterableHostLocationWithWorkspaceGroupsSchema";

export const getPricingDetailsForWorkspaceGroup = ({
    bookingEndDateTime,
    bookingStartDateTime,
    workspaceGroup,
}: {
    workspaceGroup:
        | TMinimalFilterableHostLocationWithWorkspaceGroupsSchema["workspace_groups"][0]
        | NoValue;
    bookingStartDateTime: Date | NoValue;
    bookingEndDateTime: Date | NoValue;
}) => {
    const hasDateTimeSet =
        hasValue(bookingStartDateTime) && hasValue(bookingEndDateTime);

    const workspace = workspaceGroup?.workspaces.at(0);

    const duration =
        hasValue(bookingStartDateTime) && hasValue(bookingEndDateTime)
            ? bookingEndDateTime.getTime() - bookingStartDateTime.getTime()
            : null;
    const durationInHours = hasValue(duration)
        ? duration / 1000 / 60 / 60
        : null;

    const getPricingForDuration = () => {
        // We assume there is only one availability for now. Need to allow the user to select one,
        // and then calculate the price based on that, or automatically select the most relevant
        // one, once the situation arises.
        // We assume there is only one availability for now. Need to allow the user to select one,
        // and then calculate the price based on that, or automatically select the most relevant
        // one, once the situation arises.
        const availability = workspace?.availability.at(0);
        const pricingCalculation = availability?.priceCalculation;

        if (!pricingCalculation || !durationInHours) {
            return {
                pricingForDuration: null,
            };
        }

        return {
            pricingForDuration: {
                priceForDuration: centToWhole(
                    pricingCalculation.price * durationInHours
                ),
                feeForDuration: centToWhole(
                    pricingCalculation.fee * durationInHours
                ),
                totalPriceForDuration: centToWhole(
                    pricingCalculation.totalPrice * durationInHours
                ),
                totalPriceIncludingTaxForDuration: centToWhole(
                    pricingCalculation.totalPriceIncludingTax * durationInHours
                ),
            },
        };
    };

    const getPricingPerHour = () => {
        // We assume there is only one availability for now. Need to allow the user to select one,
        // and then calculate the price based on that, or automatically select the most relevant
        // one, once the situation arises.
        if (!workspaceGroup) {
            return {
                pricingPerHour: null,
            };
        }

        const hasAvailability =
            (workspaceGroup.workspaces.at(0)?.availability.length ?? 0) > 0;

        let pricingCalculation: TPricingCalculationSchema | null = null;
        if (hasDateTimeSet && hasAvailability) {
            pricingCalculation =
                workspaceGroup.workspaces.at(0)?.availability.at(0)
                    ?.priceCalculation ?? null;
        } else if (!hasAvailability || !hasDateTimeSet) {
            pricingCalculation =
                workspaceGroup.pricingInformation?.lowestPrice ?? null;
        }

        if (!pricingCalculation) {
            return {
                pricingPerHour: null,
            };
        }

        return {
            pricingPerHour: {
                pricePerHour: centToWhole(pricingCalculation.price),
                feePerHour: centToWhole(pricingCalculation.fee),
                totalPricePerHour: centToWhole(pricingCalculation.totalPrice),
                totalPriceIncludingTaxPerHour: centToWhole(
                    pricingCalculation.totalPriceIncludingTax
                ),
            },
        };
    };

    return {
        ...getPricingPerHour(),
        ...getPricingForDuration(),
    };
};
