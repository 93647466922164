import DineroFactory, { Currency } from "dinero.js";

/**
 * Simple function to convert cents to whole currency.
 * @param value
 * @param currency
 */
export const asCurrency = (value: number, currency: string) => {
    const dinero = DineroFactory({
        amount: value,
        currency: currency as Currency,
    });

    return dinero;
};
