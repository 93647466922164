import { Filter } from "@/features/filtering/domain/entities/Filter";
import deepmerge from "deepmerge";

interface ActiveFilterDataToFilteringRequestPropertiesProps {
    filters: Filter<any, any, any>[];
    queryParams: Record<string, string>;
}

export const activeFilterDataToFilteringRequestPropertiesString = ({
    filters,
    queryParams,
}: ActiveFilterDataToFilteringRequestPropertiesProps): Record<
    string,
    string | string[]
> => {
    let result: Record<string, string | string[]> = {};

    for (const filter of filters) {
        const queryParamsForBackendRequest =
            filter.filterResourcesRemotely?.(queryParams);

        if (!queryParamsForBackendRequest) {
            continue;
        }

        result = deepmerge(result, queryParamsForBackendRequest.queryParams);
    }

    return result;
};
