import { hasValue } from "@/common/utilities/hasValue";
import { asConvertedCurrency } from "@/common/utilities/math/asConvertedCurrency";
import { getPricingDetailsForWorkspaceGroup } from "@/common/utilities/math/getPricingDetailsForWorkspaceGroup";
import { maxFilterPrice } from "@/component-library/components/currency/converted-currency/supportedCurrencies";
import { Filter } from "@/features/filtering/domain/entities/Filter";
import {
    dateTimeFilterQueryEndKey,
    dateTimeFilterQueryStartKey,
} from "@/features/filtering/filters/date-time-filter/dateTimeFilterQueryKeys";
import {
    pricingFilterQueryEndKey,
    pricingFilterQueryStartKey,
    pricingFilterCurrency,
} from "@/features/filtering/filters/pricing-filter/pricing-filte-query-keys";
import { TPricingFilterWorkspaceGroupLevelMetadataDataSchema } from "@/features/filtering/filters/pricing-filter/pricing-filter-schemas";
import PricingFilterComponent from "@/features/filtering/filters/pricing-filter/PricingFilterComponent";
import { pricingFilterId } from "@/features/filtering/filters/pricing-filter/pricingFilterId";
import { TMinimalFilterableHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/MinimalFilterableHostLocationWithWorkspaceGroupsSchema";

export const pricingFilter: Filter<
    undefined,
    TPricingFilterWorkspaceGroupLevelMetadataDataSchema,
    typeof pricingFilterId
> = {
    id: pricingFilterId,
    urlSyncedComponent: <PricingFilterComponent />,
    filterLocally: {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        async filterWorkspaceGroup({
            location,
            queryParams,
            workspaceGroup,
        }: {
            location: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema;
            workspaceGroup: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema["workspace_groups"][0];
            queryParams: Record<string, string>;
        }): Promise<{
            isMatch: boolean;
            filterMetadata: TPricingFilterWorkspaceGroupLevelMetadataDataSchema;
        }> {
            const currency = Object.keys(queryParams).includes(
                pricingFilterCurrency
            )
                ? queryParams[pricingFilterCurrency]
                : "EUR";

            const lowestPriceLimit = Object.keys(queryParams).includes(
                pricingFilterQueryStartKey
            )
                ? parseInt(queryParams[pricingFilterQueryStartKey])
                : 0;

            const maxBucketPrice = maxFilterPrice[currency];
            const highestPriceLimit = Object.keys(queryParams).includes(
                pricingFilterQueryEndKey
            )
                ? parseInt(queryParams[pricingFilterQueryEndKey])
                : maxBucketPrice;

            let start: Date | undefined = new Date(
                queryParams[dateTimeFilterQueryStartKey]
            );
            let end: Date | undefined = new Date(
                queryParams[dateTimeFilterQueryEndKey]
            );
            if (isNaN(start.valueOf()) || isNaN(end.valueOf())) {
                start = undefined;
                end = undefined;
            }

            const pricingDetails = getPricingDetailsForWorkspaceGroup({
                workspaceGroup,
                bookingStartDateTime: start,
                bookingEndDateTime: end,
            });

            const pricePerHour =
                pricingDetails.pricingPerHour?.totalPricePerHour;

            const convertedPricePerHour = hasValue(pricePerHour)
                ? await asConvertedCurrency(
                      Math.round(pricePerHour * 100),
                      location.location.currency.code,
                      currency
                  )
                : undefined;

            let isAboveMinPrice = hasValue(convertedPricePerHour);
            if (hasValue(lowestPriceLimit) && hasValue(convertedPricePerHour)) {
                isAboveMinPrice =
                    convertedPricePerHour.toUnit() >= lowestPriceLimit;
            }

            let isBelowMaxPrice = hasValue(convertedPricePerHour);
            if (
                hasValue(highestPriceLimit) &&
                hasValue(convertedPricePerHour) &&
                highestPriceLimit < maxBucketPrice
            ) {
                isBelowMaxPrice =
                    convertedPricePerHour.toUnit() <= highestPriceLimit;
            }

            return {
                isMatch: isAboveMinPrice && isBelowMaxPrice,
                filterMetadata: {
                    filterMaxPrice: highestPriceLimit,
                    filterMinPrice: lowestPriceLimit,
                    isAboveMinPrice: isAboveMinPrice,
                    isBelowMaxPrice: isBelowMaxPrice,
                    isWithingPriceRange: isAboveMinPrice && isBelowMaxPrice,
                },
            };
        },
        filterLocation({
            workspaceGroupsWithMetadata,
        }: {
            location: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema;
            workspaceGroupsWithMetadata: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema["workspace_groups"];
            queryParams: Record<string, string>;
        }): { isMatch: boolean; filterMetadata: undefined } {
            return {
                isMatch: workspaceGroupsWithMetadata.some(
                    (workspaceGroup) =>
                        workspaceGroup.filterMetadata?.[pricingFilterId]
                            ?.isWithingPriceRange
                ),
                filterMetadata: undefined,
            };
        },
    },
};
