import { DiscoverSceneState } from "@/app/(main)/discover/(layout)/(application)/DiscoverSceneState";
import { createStore, useStore } from "zustand";

const sceneStateStoreInitialState = () => ({
    scenes: {
        [DiscoverSceneState.sceneKey]: DiscoverSceneState.initial(),
    },
});

type SceneStateStoreState = ReturnType<typeof sceneStateStoreInitialState>;

interface SceneStateStoreActions {
    updateSceneState<
        TScene extends keyof SceneStateStoreState["scenes"],
        TState extends Partial<SceneStateStoreState["scenes"][TScene]>,
    >(
        sceneId: TScene,
        state: TState
    ): void;
}

export type SceneStateStore = SceneStateStoreState & SceneStateStoreActions;

const sceneStateStore = createStore<SceneStateStore>((set) => ({
    ...sceneStateStoreInitialState(),
    updateSceneState<
        TScene extends keyof SceneStateStoreState["scenes"],
        TState extends Partial<SceneStateStoreState["scenes"][TScene]>,
    >(sceneId: TScene, state: TState) {
        set((prevState) => ({
            scenes: {
                ...prevState.scenes,
                [sceneId]: prevState.scenes[sceneId].copyWith(state),
            },
        }));
    },
}));

export const useSceneStateStore = <TPartialState,>(
    selector: (state: SceneStateStore) => TPartialState
) => {
    return useStore(sceneStateStore, selector);
};
