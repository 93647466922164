import { z } from "zod";

export const DateTimeFilterWorkspaceGroupLevelMetadataDataSchema = z.object({
    hasDateTimeSet: z.boolean(),
    hasRoomOfferingWithAvailability: z.boolean(),

    start: z.date().optional(),
    end: z.date().optional(),
    meetingDurationInS: z.number().nullable(),
});
export type TDateTimeFilterWorkspaceGroupLevelMetadataDataSchema = z.infer<
    typeof DateTimeFilterWorkspaceGroupLevelMetadataDataSchema
>;

export const DateTimeFilterLocationLevelMetadataDataSchema = z.object({
    hasDateTimeSet: z.boolean(),
    hasRoomOfferingWithAvailability: z.boolean(),

    start: z.date().optional(),
    end: z.date().optional(),
    meetingDurationInS: z.number().nullable(),
});
export type TDateTimeFilterLocationLevelMetadataDataSchema = z.infer<
    typeof DateTimeFilterLocationLevelMetadataDataSchema
>;
