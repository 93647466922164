import { useDiscoverMapStore } from "@/app/(main)/discover/[[...params]]/(application)/useDiscoverMapStore";
import { NetworkEndpoints } from "@/configs/NetworkEndpoints";
import { WezooSWRConfig } from "@/configs/WezooSWRConfig";
import { activeFilterDataToFilteringRequestPropertiesString } from "@/features/filtering/application/activeFilterDataToFilteringRequestPropertiesString";
import { capacityFilter } from "@/features/filtering/filters/capacity-filter/CapacityFilter";
import { dateTimeFilter } from "@/features/filtering/filters/date-time-filter/dateTimeFilter";
import { facilityFilter } from "@/features/filtering/filters/facility-filter/facilityFilter";
import { pricingFilter } from "@/features/filtering/filters/pricing-filter/pricingFilter";
import { resourceTypeFilter } from "@/features/filtering/filters/resource-type-filter/resourceTypeFilter";
import { getHostLocationsFetcher } from "@/features/host-locations/application/getHostLocationsFetcher";
import { SimplifiedHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/SimplifiedHostLocationWithWorkspaceGroupsSchema";
import { TResourceSchema } from "@/features/resource/domain/entities/ResourceSchema";
import {
    TWezooResourceTypeSchema,
    WezooResourceTypeSchema,
} from "@/features/resource/resource-types/shared/WezooResourceType";
import { useSearchParams } from "next/navigation";
import useSWRInfinite from "swr/infinite";
import { useDebounceValue } from "usehooks-ts";
import { z, ZodArray } from "zod";
import { useMemo, useEffect } from "react";
import hash from "object-hash";

interface UseHostLocationsProps {
    resourceTypes?: TWezooResourceTypeSchema[];
    resources?: TResourceSchema[];
    applyFilters?: boolean;
    hostLocationId?: string;
}

const PAGE_SIZE = 5; // Define your page size here

export const useHostLocations = ({
    resourceTypes = [
        WezooResourceTypeSchema.enum.ROOM,
        WezooResourceTypeSchema.enum.HOTDESK,
    ],
    applyFilters = true,
    hostLocationId,
}: UseHostLocationsProps) => {
    const queryParams = useSearchParams();
    const mapViewBounds = useDiscoverMapStore((state) => state.mapViewBounds);

    const relevantFilters = activeFilterDataToFilteringRequestPropertiesString({
        queryParams: Object.fromEntries(queryParams.entries()),
        filters: [
            resourceTypeFilter,
            dateTimeFilter,
            pricingFilter,
            facilityFilter,
            capacityFilter,
        ],
    });

    const roundedMapViewBounds = useMemo(
        () =>
            mapViewBounds
                ? {
                      minLatitude: Math.floor(mapViewBounds.minLatitude),
                      maxLatitude: Math.ceil(mapViewBounds.maxLatitude),
                      minLongitude: Math.floor(mapViewBounds.minLongitude),
                      maxLongitude: Math.ceil(mapViewBounds.maxLongitude),
                  }
                : null,

        [mapViewBounds]
    );

    const relevantQueryParams = Object.fromEntries(queryParams.entries());
    delete relevantQueryParams["minLatitude"];
    delete relevantQueryParams["maxLatitude"];
    delete relevantQueryParams["minLongitude"];
    delete relevantQueryParams["maxLongitude"];

    // Build the filter set and compute its hash
    const filterSet = useMemo(
        () => ({
            applyFilters,
            hostLocationId,
            relevantFilters,
            roundedMapViewBounds,
            resourceTypes,
            relevantQueryParams,
        }),
        [
            applyFilters,
            hostLocationId,
            relevantFilters,
            roundedMapViewBounds,
            resourceTypes,
            relevantQueryParams,
        ]
    );

    const filterSetHash = useMemo(() => hash(filterSet), [filterSet]);

    // Key generator function for useSWRInfinite
    const getKey = (
        pageIndex: number,
        previousPageData: z.infer<
            ZodArray<typeof SimplifiedHostLocationWithWorkspaceGroupsSchema>
        > | null
    ) => {
        if (previousPageData && !previousPageData.length) {
            return null; // No more pages
        }

        // Return the full URL with hash to ensure uniqueness
        return [NetworkEndpoints.workspaceGroupsV2(), filterSetHash, pageIndex];
    };

    const fetcher = async (key: [string, string, number]) => {
        const [endpoint, , page] = key;
        return await getHostLocationsFetcher({
            endpoint: endpoint,
            applyFilters: filterSet.applyFilters,
            hostLocationId: filterSet.hostLocationId,
            mapViewBounds: roundedMapViewBounds,
            queryParams: relevantQueryParams,
            locationSchema:
                SimplifiedHostLocationWithWorkspaceGroupsSchema.array(),
            page,
            count: hostLocationId ? 1 : PAGE_SIZE,
        });
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - SWRInfinite types are not compatible with Zod types
    const { data, error, size, setSize, isValidating } = useSWRInfinite<
        z.infer<
            ZodArray<typeof SimplifiedHostLocationWithWorkspaceGroupsSchema>
        >
    >(
        (pageIndex, previousPageData) => {
            const key = getKey(pageIndex, previousPageData);
            return key;
        },
        fetcher,
        {
            ...WezooSWRConfig.defaultSWRConfig,
            revalidateFirstPage: false,
        }
    );

    // Flatten the data array
    const hostLocations = data ? data.flat() : [];

    // Determine loading states
    const isLoadingInitialData = !data && !error;
    const isLoadingMore =
        isLoadingInitialData ||
        (size > 0 && data && typeof data[size - 1] === "undefined");
    const isFetching = isValidating || isLoadingMore;

    // Determine if there is more data to load (i.e. the last page has data)
    const hasMore =
        data && data[data.length - 1]
            ? data[data.length - 1].length > 0
            : false;

    // Automatically load more data if there are more pages
    useEffect(() => {
        if (!isFetching && hasMore) {
            void setSize(size + 1);
        }
    }, [hasMore, isFetching]);

    const locallyFilteredHostLocations = mapViewBounds
        ? hostLocations.filter((location) => {
              return (
                  location &&
                  location.location &&
                  location.location.longitude >= mapViewBounds.minLongitude &&
                  location.location.longitude <= mapViewBounds.maxLongitude &&
                  location.location.latitude >= mapViewBounds.minLatitude &&
                  location.location.latitude <= mapViewBounds.maxLatitude
              );
          })
        : hostLocations;

    const [debouncedIsFetching] = useDebounceValue(isFetching || false, 1000);
    // const [debouncedHostLocations] = useDebounceValue(
    //     locallyFilteredHostLocations,
    //     100
    // );

    return {
        hostLocations: locallyFilteredHostLocations,
        isFetching: debouncedIsFetching,
        hasMore,
        error,
    };
};
