import { buildErrorWithPropsAndPayload } from "@/common/application/errors/error-builders";
import { prettyPrintObjectToString } from "@/common/application/prettyPrintObjectToString";
import { UserFacingErrorBuilder } from "@/common/domain/errors/error-types/UserFacingErrorBuilder";
import { z } from "zod";

export const FailedToParseDataAccordingToSchemaError =
    buildErrorWithPropsAndPayload({
        errorName: "FailedToParseDataAccordingToSchemaError",
        builder: UserFacingErrorBuilder,
        payload: z.object({
            userFacingErrorMessage: z.string().optional(),
            message: z.string(),
            schemaStructure: z.string(),
            actualStructure: z.string(),
            wrappedError: z.string().optional(),
        }),
        build: (
            builder,
            props: {
                message: string;
                schemaStructure: z.Schema;
                actualStructure: any;
                wrappedError?: string;
                userFacingErrorMessage?: string;
            }
        ) =>
            builder
                .enableAnalytics()
                .setInternalMessage(
                    `Data could not be parsed by a schema:\n[${
                        props.wrappedError ?? "no wrapped error"
                    }]\n`
                )
                .setUserFacingErrorMessage(
                    props.userFacingErrorMessage ??
                        `Did not understand server response. Please, try again.`
                )
                .addPayload({
                    userFacingErrorMessage: props.userFacingErrorMessage,
                    message: props.message,
                    schemaStructure: prettyPrintObjectToString(
                        props.schemaStructure
                    ),
                    actualStructure:
                        typeof props.actualStructure === "object"
                            ? prettyPrintObjectToString(props.actualStructure)
                            : props.actualStructure?.toString() ?? "undefined",
                    wrappedError: props.wrappedError,
                }),
    });
