import { AvailabilitySchema } from "@/features/host-locations/domain/entities/schemas/AvailabilitySchema";
import { z } from "zod";

export const WorkspaceSchema = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    allocations: z.number(),
    availability: AvailabilitySchema.array(),
});
export type TWorkspaceSchema = z.infer<typeof WorkspaceSchema>;
