import { z } from "zod";

export const PricingOverviewInformationSchema = z.object({
    lowestPrice: z.object({
        price: z.number(),
        fee: z.number(),
        totalPrice: z.number(),
        totalPriceIncludingTax: z.number(),
        currency: z.object({
            code: z.string(),
        }),
        // Either "HOUR" or "DAY"
        intervalLength: z.enum(["HOUR", "DAY"]),
    }),
});
export type TPricingOverviewInformation = z.infer<
    typeof PricingOverviewInformationSchema
>;
