import {
    WezooError,
    WezooErrorMetadata,
} from "@/common/domain/errors/_base/WezooError";
import { AnalyticsEventType } from "@/features/analytics/domain/entities/AnalyticsEventType";
import { assert } from "ts-essentials";

export interface ErrorBuilderInterface<TPayload> {
    __callBuild(errorName: string, triggeredBy?: string): WezooError<TPayload>;
}

export abstract class _WezooErrorBuilder<TPayload = undefined>
    implements ErrorBuilderInterface<TPayload>
{
    abstract __errorType: string;

    __errorName: string | undefined;

    __internalMessage: string | undefined;
    __userFacingErrorMessage: string | undefined;
    __metadata: WezooErrorMetadata | undefined;

    __enabledAnalytics: boolean | undefined;

    __payload: TPayload | undefined;

    __isAlreadyBuilt: boolean = false;

    constructor() {}

    __callBuild(errorName: string, triggeredBy?: string): WezooError<TPayload> {
        return this.build(errorName, triggeredBy);
    }

    disableAnalytics(): this {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), {
            ...this,
            __enabledAnalytics: false,
        });
    }

    enableAnalytics(): this {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), {
            ...this,
            __enabledAnalytics: true,
        });
    }

    setInternalMessage(internalMessage: string): this {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), {
            ...this,
            __internalMessage: internalMessage,
        });
    }

    setUserFacingErrorMessage(message: string): this {
        this.__userFacingErrorMessage = message;
        return Object.assign(Object.create(Object.getPrototypeOf(this)), {
            ...this,
            metadata: { ...this.__metadata, __userFacingErrorMessage: message },
        });
    }

    addMetadata(additionalMetadata: typeof this.__metadata): this {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), {
            ...this,
            __metadata: { ...this.__metadata, ...additionalMetadata },
        });
    }

    addPayload(payload: TPayload): this {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), {
            ...this,
            __payload: payload,
        });
    }

    protected build(
        errorName: string,
        triggeredBy?: string
    ): WezooError<TPayload> {
        assert(
            !this.__isAlreadyBuilt,
            `The error [${this.__errorName}] has already been built!`
        );
        assert(
            !!this.__internalMessage,
            "You have to set internal message for the error!"
        );
        assert(
            this.__enabledAnalytics !== undefined,
            "You have to decide whether to enable or disable analytics for the error!"
        );

        // Do not allow the same error to be built multiple times
        this.__isAlreadyBuilt = true;

        return new WezooError<TPayload>({
            builder: this,
            analyticsEventType: AnalyticsEventType.error,
            enabledAnalytics: this.__enabledAnalytics,
            internalMessage: this.__internalMessage,
            userFacingMessage: this.__userFacingErrorMessage,
            errorType: this.__errorType,
            metadata: {
                ...this.__metadata,
            },
            payload: this.__payload as TPayload,
            triggeredBy,
            errorName,
        });
    }
}
