"use client";

import UserFacingErrorMessageDisplay from "@/component-library/components/feedback/user-facing-error-message-display/UserFacingErrorMessageDisplay";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";

interface CatchErrorsAndDisplayMessageProps {
    children: React.ReactNode;
}

const CatchErrorsAndDisplayMessage: React.FC<
    CatchErrorsAndDisplayMessageProps
> = ({ children }) => {
    return (
        <ErrorBoundary fallbackRender={UserFacingErrorMessageDisplay}>
            {children}
        </ErrorBoundary>
    );
};

export default CatchErrorsAndDisplayMessage;
