import Flex from "@/component-library/components/organising-content/flex/Flex";
import _SingleRangeInputField from "@/component-library/components/user-input/range-input-fields/_SingleRangeInputField";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import React, { useCallback } from "react";

interface RangeInputFieldsProps {
    values: { begin: number; end: number };
    onValuesChange?: (values: { begin: number; end: number }) => void;
    formatBeginValue?: (value: number) => string;
    formatEndValue?: (value: number) => string;

    min: number;
    max: number;
    step?: number;
}

const RangeInputFields: React.FC<RangeInputFieldsProps> = ({
    onValuesChange,
    values,
    formatBeginValue,
    formatEndValue,
    min,
    max,
    step = 1,
}) => {
    const onChange = useCallback(
        ({ begin, end }: { begin: number; end: number }) => {
            let updatedValues = {
                begin,
                end,
            };

            // Make sure that begin is less than end
            if (
                updatedValues.end === values.end &&
                updatedValues.end <= begin
            ) {
                updatedValues = {
                    begin: begin,
                    end: begin + step,
                };
            }

            // make sure that end is greater than begin
            if (
                updatedValues.begin === values.begin &&
                updatedValues.begin >= end
            ) {
                updatedValues = {
                    begin: end - step,
                    end: end,
                };
            }

            // Do not allow end to go below min + step or begin to go above max - step
            if (updatedValues.end <= min + step) {
                updatedValues = {
                    begin: updatedValues.begin,
                    end: step,
                };
            } else if (updatedValues.begin >= max - step) {
                updatedValues = {
                    begin: max - step,
                    end: updatedValues.end,
                };
            }

            onValuesChange?.(updatedValues);
        },
        [max, min, onValuesChange, step, values.begin, values.end]
    );

    return (
        <Flex
            direction="row"
            justifyContent="stretch"
            alignItems="center"
            gap={Magnitudes.spacingInRem.s}
        >
            <_SingleRangeInputField
                onValueChange={(value) =>
                    onChange({ begin: value, end: values.end })
                }
                value={values.begin}
                min={min}
                max={values.end - step}
                step={step}
                formatValue={formatBeginValue}
            />
            <_SingleRangeInputField
                onValueChange={(value) =>
                    onChange({ begin: values.begin, end: value })
                }
                value={values.end}
                min={values.begin + step}
                max={max}
                step={step}
                formatValue={formatEndValue}
            />
        </Flex>
    );
};

export default RangeInputFields;
