import { hasValue } from "@/common/utilities/hasValue";

export const zeroPrefixedNumberToNumber = (value: string) => {
    const trimmedValue = value.trim();
    if (
        trimmedValue.length > 0 &&
        Array.from(trimmedValue).every((char) => char === "0")
    ) {
        return 0;
    }

    const valueWithoutPrefixZeros = trimmedValue.replace(/^0+/, "");

    const currentValueAsNumber =
        valueWithoutPrefixZeros.length === 0
            ? null
            : parseInt(valueWithoutPrefixZeros);

    if (!hasValue(currentValueAsNumber)) {
        return null;
    }

    return isNaN(currentValueAsNumber) ? null : currentValueAsNumber;
};
