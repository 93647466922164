/**
 * For convenience, `public static get viewId(): string` should be implemented to easily access
 * the view id when requesting the view state from [UserViewStateStore]. Currently, there is no
 * straightforward way to enforce abstract static properties on abstract classes or interfaces
 * without "hacky"-solutions.
 */
export abstract class SceneState<TStateConstructorProps extends object> {
    protected constructor() {}

    abstract copyWith(
        copyProps: Partial<TStateConstructorProps>
    ): SceneState<TStateConstructorProps>;
}
