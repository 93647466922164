import { TViewBoundsSchema } from "@/common/domain/entities/locations/ViewBoundsSchema";
import { MapState } from "@/features/host-locations/domain/entities/MapState";
import { create } from "zustand";

export const DEFAULT_INITIAL_ZOOM = 6;

export interface DiscoverMapStoreState {
    mapState: MapState | null;
    mapViewBounds: TViewBoundsSchema | null;

    highlightedHostLocationId: string | null;
}

const initialState = (): DiscoverMapStoreState => ({
    mapViewBounds: null,
    mapState: null,
    highlightedHostLocationId: null,
});

export interface DiscoverMapStoreActions {
    setMapState: (mapState: MapState) => void;
    setMapBounds: (bounds: TViewBoundsSchema) => void;
    setHighlightedHostLocationId: (hostLocationId: string | null) => void;
}

export const useDiscoverMapStore = create<
    DiscoverMapStoreState & DiscoverMapStoreActions
>((set) => ({
    ...initialState(),
    setMapState: (mapState) => {
        set({ mapState });
    },
    setMapBounds: (bounds) => {
        set({ mapViewBounds: bounds });
    },
    setHighlightedHostLocationId(hostLocationId) {
        set({ highlightedHostLocationId: hostLocationId });
    },
}));
