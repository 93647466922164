import { reportError } from "@/common/application/debug";
import { prettyPrintObjectToString } from "@/common/application/prettyPrintObjectToString";
import { TViewBoundsSchema } from "@/common/domain/entities/locations/ViewBoundsSchema";
import { NoValue } from "@/common/domain/entities/typing/NoValue";
import { hasValue } from "@/common/utilities/hasValue";
import { NetworkEndpoints } from "@/configs/NetworkEndpoints";
import { NetworkRequestError } from "@/features/backend/domain/errors/NetworkRequestError";
import { fetcher } from "@/features/backend/fetcher";
import { activeFilterDataToFilteringRequestPropertiesString } from "@/features/filtering/application/activeFilterDataToFilteringRequestPropertiesString";
import { dateTimeFilter } from "@/features/filtering/filters/date-time-filter/dateTimeFilter";
import { getLocationsInView } from "@/features/host-locations/application/getLocationsInView";
import { AvailableFilters } from "@/features/WezooResourceData";
import { z } from "zod";

interface GetHostLocationsFetcherProps<TLocationSchema extends z.Schema> {
    endpoint?: string;

    hostLocationId: string | NoValue;
    queryParams: Record<string, string>;
    mapViewBounds?: TViewBoundsSchema | NoValue;

    applyFilters: boolean;
    filterOnlyOnDateTime?: boolean;

    locationSchema: TLocationSchema;

    page?: number;
    count?: number;
}

export async function getHostLocationsFetcher<
    TLocationSchema extends z.Schema,
>({
    endpoint = NetworkEndpoints.workspaceGroups(),
    hostLocationId,
    mapViewBounds,
    applyFilters,
    filterOnlyOnDateTime = false,
    queryParams,
    locationSchema,
    page = 0,
    count = 100,
}: GetHostLocationsFetcherProps<TLocationSchema>): Promise<z.infer<TLocationSchema> | null> {
    const filteringRequestProperties = () => {
        if (filterOnlyOnDateTime) {
            return activeFilterDataToFilteringRequestPropertiesString({
                queryParams: queryParams,
                filters: [dateTimeFilter],
            });
        }
        return applyFilters
            ? activeFilterDataToFilteringRequestPropertiesString({
                  queryParams: queryParams,
                  filters: AvailableFilters,
              })
            : {};
    };

    // if (applyFilters && !hasValue(mapViewBounds)) {
    //     reportError(
    //         "MapViewBounds must be provided when applyFilters is true."
    //     );
    //     return Promise.resolve(null);
    // }

    const properties = filteringRequestProperties();
    return fetcher<z.infer<TLocationSchema>>(
        endpoint,
        {
            resourceType: ["ROOM", "HOTDESK"],
            locationId: hostLocationId ?? undefined,
            minLat: mapViewBounds?.minLatitude,
            maxLat: mapViewBounds?.maxLatitude,
            minLong: mapViewBounds?.minLongitude,
            maxLong: mapViewBounds?.maxLongitude,
            count,
            page,
            ...properties,
        },
        {
            priority: "low",
        },
        async (response): Promise<z.infer<TLocationSchema> | null> => {
            const data = await response.json();
            if (response.status !== 200) {
                throw NetworkRequestError({
                    statusCode: response.status,
                    userFacingMessage:
                        "Could not get locations. Please try again.",
                });
            }

            if (!hasValue(data)) {
                throw NetworkRequestError({
                    userFacingMessage: `Could not get locations. Please try again. [Unexpected response type ${typeof data}].`,
                });
            }

            const dataConversion = locationSchema.safeParse(data);
            if (!dataConversion.success) {
                /**
                 * Does not warrant throwing an error if some of the conversions
                 * fail. These need to be reported to analytics so that we can fix
                 * them, but those records that were correctly parsed should be
                 * shown to the user to avoid completely breaking the user
                 * experience.
                 */
                reportError(
                    "Failed to parse location: ",
                    prettyPrintObjectToString(dataConversion.error),
                    "\nWith raw data: ",
                    prettyPrintObjectToString(data)
                );
                return null;
            }

            let result = dataConversion.data;
            if (applyFilters) {
                result = getLocationsInView({
                    locations: dataConversion.data,
                    bounds: mapViewBounds,
                });
            }

            return result;
        }
    ).catch(() => {
        return null;
    });
}
