import { facilitiesFilteringStore } from "@/features/filtering/filters/facility-filter/facilitiesFilteringStore";
import { facilityFilterQueryRequiredFacilitiesKey } from "@/features/filtering/filters/facility-filter/facilityFilterQueryParams";
import { useSearchOptions } from "@/features/filtering/filters/facility-filter/useSearchOptions";
import { TFacilitySchema } from "@/features/resource/domain/entities/FacilitySchema";
import { parseAsArrayOf, parseAsString, useQueryState } from "nuqs";
import { useCallback, useEffect, useLayoutEffect } from "react";

export const useFacilitiesFilter = () => {
    const [requiredFacilitiesQueryParams, setRequiredFacilitiesQueryParams] =
        useQueryState(
            facilityFilterQueryRequiredFacilitiesKey,
            parseAsArrayOf(parseAsString)
        );

    const { data: facilitiesAndFacilityGroupsData, isLoading } =
        useSearchOptions();

    const setAllAvailableFacilitiesAndGroups = facilitiesFilteringStore(
        (state) => state.setAllAvailableFacilitiesAndGroups
    );

    const setRequiredFacilitiesInStore = facilitiesFilteringStore(
        (state) => state.setRequired
    );

    const storeStateIsReady = facilitiesFilteringStore(
        (state) => state.stateIsReady
    );

    const setStoreStateIsReady = facilitiesFilteringStore(
        (state) => state.setStateIsReady
    );

    useEffect(() => {
        /**
         * Update the facilitiesFilteringStore with the available facilities, groups, and grouped
         * facilities data.
         */
        if (isLoading) {
            return;
        }

        setAllAvailableFacilitiesAndGroups({
            allAvailableFacilities:
                facilitiesAndFacilityGroupsData?.allFacilities ?? null,
            facilityGroups:
                facilitiesAndFacilityGroupsData?.facilityGroups ?? null,
            facilitiesInGroups:
                facilitiesAndFacilityGroupsData?.facilitiesInGroups ?? null,
        });
        setStoreStateIsReady(true);
    }, [
        facilitiesAndFacilityGroupsData,
        isLoading,
        setAllAvailableFacilitiesAndGroups,
        setStoreStateIsReady,
    ]);

    const requiredFacilities = facilitiesFilteringStore(
        (state) => state.required
    );

    const setRequiredFacilities = useCallback(
        (newRequiredFacilities: TFacilitySchema[] | null) => {
            void setRequiredFacilitiesQueryParams(
                newRequiredFacilities?.map((f) => f.slug) ?? null
            );
            setRequiredFacilitiesInStore(newRequiredFacilities ?? null);
        },
        [setRequiredFacilitiesInStore, setRequiredFacilitiesQueryParams]
    );

    useLayoutEffect(() => {
        if (!facilitiesAndFacilityGroupsData) {
            return;
        }

        /**
         * Update required facilities on launch.
         */
        setRequiredFacilitiesInStore(
            facilitiesAndFacilityGroupsData.allFacilities.filter(
                (facility) =>
                    requiredFacilitiesQueryParams?.includes(facility.slug) ??
                    false
            )
        );
    }, [
        facilitiesAndFacilityGroupsData,
        requiredFacilitiesQueryParams,
        setRequiredFacilitiesInStore,
    ]);

    return {
        facilitiesStateIsReady: storeStateIsReady,
        requiredFacilities,
        setRequiredFacilities,
        ...facilitiesAndFacilityGroupsData,
    };
};
