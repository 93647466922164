"use client";

import { useApplyableParameterStore } from "@/app/(main)/discover/[[...params]]/(application)/useApplyableParameterStore";
import { reportInfo } from "@/common/application/debug";
import { toDDMMYYYY } from "@/common/utilities/date/toDDMMYYYY";
import { toHHMM } from "@/common/utilities/date/toHHMM";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import AppBarFilter from "@/features/filtering/components/AppBarFilter";
import { parseAsTimezonelessDate } from "@/features/filtering/filters/date-time-filter/date-time-filter-parser";
import { dateTimeFilter } from "@/features/filtering/filters/date-time-filter/dateTimeFilter";
import DateTimeFilterPopper from "@/features/filtering/filters/date-time-filter/DateTimeFilterPopper";
import {
    dateTimeFilterQueryEndKey,
    dateTimeFilterQueryStartKey,
} from "@/features/filtering/filters/date-time-filter/dateTimeFilterQueryKeys";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { parseAsIsoDateTime, useQueryState } from "nuqs";
import React, { useEffect, useState } from "react";

interface DateTimeFilterToolbarChipProps {}

const DateTimeFilterToolbarChip: React.FC<
    DateTimeFilterToolbarChipProps
> = () => {
    const { setParam } = useApplyableParameterStore();

    const [startQueryParam] = useQueryState(
        dateTimeFilterQueryStartKey,
        parseAsIsoDateTime
    );
    const [endQueryParam] = useQueryState(
        dateTimeFilterQueryEndKey,
        parseAsIsoDateTime
    );

    const [startState, setStartState] = useState<Date | null>(
        startQueryParam || null
    );
    const [endState, setEndState] = useState<Date | null>(
        endQueryParam || null
    );

    useEffect(() => {
        reportInfo("DateTimeFilterToolbarChip", {
            startState,
            endState,
        });

        setParam(
            dateTimeFilterQueryStartKey,
            startState
                ? parseAsTimezonelessDate.serialize(startState)
                : undefined
        );
        setParam(
            dateTimeFilterQueryEndKey,
            endState ? parseAsTimezonelessDate.serialize(endState) : undefined
        );
    }, [setParam, startState, endState]);

    return (
        <AppBarFilter
            filterId={dateTimeFilter.id}
            isActive={!!(startState || endState)}
            popover={
                <DateTimeFilterPopper
                    startDateTime={startState}
                    setStartDateTime={setStartState}
                    endDateTime={endState}
                    setEndDateTime={setEndState}
                />
            }
            onRemove={() =>
                void Promise.all([setStartState(null), setEndState(null)])
            }
            widthBreakpointBelowWhichPopoverTakesFullWidth={430}
            removePadding
        >
            <FontAwesomeIcon icon={faClock} />
            <div
                style={assignInlineVars({
                    fontSize: `${Magnitudes.fontInRem.m}rem`,
                })}
            >
                {endState && startState
                    ? `${toDDMMYYYY(startState)} | ${toHHMM(
                          startState
                      )}-${toHHMM(endState)}`
                    : "When"}
            </div>
        </AppBarFilter>
    );
};

export default DateTimeFilterToolbarChip;
