import { FilteringConfig } from "@/configs/discover/FilteringConfig";

export class WezooSWRConfig {
    static readonly defaultSWRConfig = {
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
        refreshInterval: FilteringConfig.filterRefreshInterval,
        keepPreviousData: true,
    };

    private constructor() {}
}
