import { z } from "zod";

/**
 * All the offered Wezoo resource types.
 */
const _WezooResourceType = {
    ROOM: "ROOM",
    HOTDESK: "HOTDESK",
} as const;

export const WezooResourceTypeValues = Object.values(_WezooResourceType);
export type TWezooResourceTypeValues = (typeof WezooResourceTypeValues)[number];

/**
 * Provides immutable access to the Wezoo resource types object as an enum. For
 * native enum behaviour, you can use [WezooResourceType.enum]
 */
export const WezooResourceTypeSchema = z.nativeEnum(_WezooResourceType);
export type TWezooResourceTypeSchema = z.infer<typeof WezooResourceTypeSchema>;
