import { NoValue } from "@/common/domain/entities/typing/NoValue";
import { hasValue } from "@/common/utilities/hasValue";
import { DateTime } from "luxon";

/**
 * Converts a date to a string in the format HH:MM
 * @param date
 * @param separator defaults to `:`.
 */
export const toHHMM = (date: Date | DateTime | NoValue, separator?: string) => {
    if (!hasValue(date)) {
        return "";
    }
    const hours = date instanceof DateTime ? date.get("hour") : date.getHours();
    const minutes =
        date instanceof DateTime ? date.get("minute") : date.getMinutes();

    const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;

    separator = separator ?? ":";

    return `${hoursString}${separator}${minutesString}`;
};
