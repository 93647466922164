import { buildErrorWithProps } from "@/common/application/errors/error-builders";
import { UserFacingErrorBuilder } from "@/common/domain/errors/error-types/UserFacingErrorBuilder";

export const NetworkConnectionFailed = buildErrorWithProps({
    errorName: "NetworkConnectionFailed",
    builder: UserFacingErrorBuilder,
    build: (
        builder,
        props: { connectionErrorMessageForInternalProcessing: string }
    ) =>
        builder
            .enableAnalytics()
            .setInternalMessage(
                props.connectionErrorMessageForInternalProcessing
            )
            .setUserFacingErrorMessage(
                "Network connection failed. Please, try again."
            ),
});
