import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { Select } from "@/component-library/components/user-input/select/Select";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import React, { ComponentProps } from "react";

type TCurrency = {
    code: string;
    name: string;
    icon: string;
};

interface CurrencySelectProps extends ComponentProps<typeof Select> {
    currencies?: TCurrency[];
}

const CurrencySelect: React.FC<CurrencySelectProps> = ({
    currencies = [],
    ...restProps
}) => {
    return (
        <Select
            postfixIcon={<FontAwesomeIcon icon={faCaretDown} />}
            {...restProps}
        >
            <optgroup>
                {currencies.map((option) => (
                    <option key={option.code} value={option.code}>
                        {option.icon} {option.name}
                    </option>
                ))}
            </optgroup>
        </Select>
    );
};

export default CurrencySelect;
