import { z } from "zod";

export enum RatingsType {
    overall = "OVERALL",
}

export const RatingsTypeSchema = z.nativeEnum(RatingsType);

export const RatingsSchema = z.object({
    type: RatingsTypeSchema,
    value: z.number(),
    amount: z.number(),
});
export type TRatingsSchema = z.infer<typeof RatingsSchema>;
