import { skeletonStyle } from "@/component-library/components/skeleton-style.css";
import { checkboxRecipe } from "@/component-library/components/user-input/checkbox/CheckboxStyles.css";
import { FormEntry } from "@/component-library/components/user-input/forms/form-entry/FormEntry";
import { theme } from "@/component-library/themes/theme.css";
import { facilityFilterAppBarComponentPopoverStyle } from "@/features/filtering/filters/facility-filter/FacilityFilterAppBarComponent.css";
import { facilityToggleableFilterStyle } from "@/features/filtering/filters/facility-filter/FacilityToggleableFilter.css";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React, { FC, PropsWithChildren } from "react";
import { useMediaQuery } from "usehooks-ts";

const SkeletonHeading: FC<PropsWithChildren> = () => {
    return (
        <h2>
            <span
                className={clsx(skeletonStyle)}
                style={assignInlineVars({
                    width: "120px",
                })}
            >
                Loading <VisuallyHidden>facility groups...</VisuallyHidden>
            </span>
        </h2>
    );
};

const SkeletonEntry: FC = () => {
    return (
        <FormEntry
            label={
                <span
                    className={clsx(skeletonStyle)}
                    style={assignInlineVars({
                        display: `inline-block`,
                        width: `calc(80px + ${Math.random() * 60}px)`,
                    })}
                >
                    Loading <VisuallyHidden>facility...</VisuallyHidden>
                </span>
            }
            alignLabelRightOfField
            className={clsx(facilityToggleableFilterStyle)}
        >
            <div
                className={clsx(
                    checkboxRecipe({
                        variant: "inactive",
                    }),
                    skeletonStyle
                )}
                style={assignInlineVars({
                    backgroundColor:
                        theme.colors.surface.loadingSurface
                            .startAndEndAnimationAtThisColor,
                    border: `none`,
                })}
            />
        </FormEntry>
    );
};

interface FacilityFilterAppBarPopoverSkeletonProps {}

const FacilityFilterAppBarPopoverSkeleton: React.FC<
    FacilityFilterAppBarPopoverSkeletonProps
> = () => {
    const shouldWrap = useMediaQuery("screen and (max-width: 1100px)");

    return (
        <div
            className={facilityFilterAppBarComponentPopoverStyle}
            style={assignInlineVars({
                gridTemplateColumns: shouldWrap
                    ? "1fr"
                    : `repeat(3, min(320px, 80vw))`,
            })}
        >
            <div>
                <SkeletonHeading />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
            </div>
            <div>
                <SkeletonHeading />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
            </div>
            <div>
                <SkeletonHeading />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
                <SkeletonEntry />
            </div>
        </div>
    );
};

export default FacilityFilterAppBarPopoverSkeleton;
