import DineroFactory, { Currency } from "dinero.js";
import { DateTime } from "luxon";
import { reportError } from "@/common/application/debug";

const exchangeRateCache = new Map<
    string,
    { data: { [key: string]: number }; timestamp: number }
>();
exchangeRateCache.clear();
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

async function cachedFetcher(url: string) {
    const now = Date.now();
    const cacheEntry = exchangeRateCache.get(url);

    if (cacheEntry && now - cacheEntry.timestamp < CACHE_DURATION) {
        // Return cached data
        return cacheEntry.data;
    }

    const response = await fetch(url);
    const data = await response.json();

    // Store in cache with timestamp
    exchangeRateCache.set(url, { data, timestamp: now });

    return data;
}

/**
 * Simple function to convert cents to whole currency.
 * @param value The value to convert in lowest denomination, e.g., cents.
 * @param currency The original currency of the value.
 * @param toCurrency The target currency to convert the value to.
 * @param onDate The date to use the conversion rate from.
 */
export const asConvertedCurrency = async (
    value: number,
    currency: string,
    toCurrency: string,
    onDate?: DateTime
) => {
    const dinero = DineroFactory({
        amount: value,
        currency: currency as Currency,
    });

    try {
        if (currency !== toCurrency) {
            const dateKey = onDate ? onDate.toISODate() : "latest";
            const endpoint = `https://api.frankfurter.app/${dateKey}?from=${currency}`;
            const convertedDinero = await dinero.convert(toCurrency, {
                endpoint: (async () => {
                    const data = await cachedFetcher(endpoint);
                    return data.rates as { [key: string]: number };
                })(),
                propertyPath: `${toCurrency}`,
            });
            return convertedDinero;
        } else {
            return dinero;
        }
    } catch (error) {
        reportError("Error converting currency.");
        reportError(error);
        return dinero;
    }
};
