import { Lazy } from "@/common/domain/entities/Lazy";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { TResourceDataBuilder } from "@/features/resource/domain/entities/ResourceDataSchema";
import { TReadOnlyResourceSchema } from "@/features/resource/domain/entities/ResourceSchema";
import {
    MeetingRoomAcceptedBookingParamsSchema,
    MeetingRoomBookingPropertiesSchema,
} from "@/features/resource/resource-types/meeting-room/domain/entities/MeetingRoomBookingSchemas";
import { WezooResourceTypeSchema } from "@/features/resource/resource-types/shared/WezooResourceType";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";

/**
 * Meeting room resource data is created on demand when accessing the [data]
 * property of [meetingRoomResource].
 */
const meetingRoomResourceData: TResourceDataBuilder = Lazy(() => {
    return {
        resourceType: WezooResourceTypeSchema.enum.ROOM,
        title: "Meeting room",
        utilitySchemas: {
            params: MeetingRoomAcceptedBookingParamsSchema,
            properties: MeetingRoomBookingPropertiesSchema,
        },
    };
});

/**
 * Represents the meeting room resource offered by Wezoo.
 */
export const meetingRoomResource: TReadOnlyResourceSchema = {
    getData: meetingRoomResourceData,
    metadata: {
        type: WezooResourceTypeSchema.enum.ROOM,
        urlSlug: "meeting-room",
        name: "meeting room",
        namePlural: "meeting rooms",
        icon: <FontAwesomeIcon icon={faDoorOpen} />,
        workspaceGroupType: "ROOM",
    },
};
