import { z } from "zod";

export const PricingFilterWorkspaceGroupLevelMetadataDataSchema = z.object({
    filterMinPrice: z.number().optional(),
    filterMaxPrice: z.number().optional(),
    currency: z.string().min(3).max(3).optional(),

    isBelowMaxPrice: z.boolean().optional(),
    isAboveMinPrice: z.boolean().optional(),
    isWithingPriceRange: z.boolean().optional(),
});
export type TPricingFilterWorkspaceGroupLevelMetadataDataSchema = z.infer<
    typeof PricingFilterWorkspaceGroupLevelMetadataDataSchema
>;
