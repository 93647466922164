"use client";

import React, { HTMLAttributes, useImperativeHandle, useRef } from "react";
import {
    selectCaretStyle,
    selectContainerStyle,
    selectStyle,
} from "./SelectStyles.css";

interface SelectProps extends HTMLAttributes<HTMLSelectElement> {
    name: string;
    value?: string;
    required?: boolean;
    postfixIcon?: React.ReactNode;
    onValueChange?: (newValue: string) => void;
    containerClassName?: string;
}

/**
 * A styled field for entering text on a single line.
 * @param postfixIcon - Icon to be displayed after the input.
 */
export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
    (
        {
            name,
            required,
            postfixIcon,
            containerClassName,
            className,
            ...restProps
        },
        refIn
    ) => {
        const inputRef = useRef<HTMLSelectElement | null>(null);

        useImperativeHandle(refIn, () => inputRef.current!, [inputRef]);

        return (
            <div
                className={`${selectContainerStyle}  ${containerClassName ?? ""}`}
                onClick={() => inputRef.current?.focus()}
            >
                <select
                    ref={inputRef}
                    className={`${selectStyle} ${className ?? ""}`}
                    name={name}
                    required={required}
                    {...restProps}
                />
                <div className={selectCaretStyle}>{postfixIcon}</div>
            </div>
        );
    }
);
Select.displayName = "Select";
