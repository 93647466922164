import { FacilitySchema } from "@/features/resource/domain/entities/FacilitySchema";
import { z } from "zod";

export const FacilityFilterLocationLevelMetadataDataSchema = z.object({
    missingServices: FacilitySchema.array(),
});
export type TFacilityFilterLocationLevelMetadataDataSchema = z.infer<
    typeof FacilityFilterLocationLevelMetadataDataSchema
>;

export const FacilityFilterWorkspaceGroupLevelMetadataDataSchema = z.object({
    missingAmenities: FacilitySchema.array(),
});
export type TFacilityFilterWorkspaceGroupLevelMetadataDataSchema = z.infer<
    typeof FacilityFilterWorkspaceGroupLevelMetadataDataSchema
>;
