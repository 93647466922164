import { Checkbox } from "@/component-library/components/user-input/checkbox/Checkbox";
import { FormEntry } from "@/component-library/components/user-input/forms/form-entry/FormEntry";
import {
    facilityToggleableFilterLabelStyle,
    facilityToggleableFilterStyle,
} from "@/features/filtering/filters/facility-filter/FacilityToggleableFilter.css";
import { TFacilitySchema } from "@/features/resource/domain/entities/FacilitySchema";
import { clsx } from "clsx";
import React, { FC } from "react";

interface FacilityToggleableFilterProps {
    facility: TFacilitySchema;
    onToggle: (toggledFacility: TFacilitySchema, isRequired: boolean) => void;
    isToggled: boolean;
}

const FacilityToggleableFilter: FC<FacilityToggleableFilterProps> = ({
    facility,
    onToggle,
    isToggled,
}) => {
    return (
        <FormEntry
            key={facility.slug}
            label={facility.display_name}
            alignLabelRightOfField
            className={clsx(facilityToggleableFilterStyle)}
            labelClassName={clsx(facilityToggleableFilterLabelStyle)}
        >
            <Checkbox
                //defaultChecked={isToggled}
                name={facility.slug}
                checked={isToggled}
                onChange={(event) => {
                    onToggle(facility, event.currentTarget.checked);
                }}
            />
        </FormEntry>
    );
};

export default FacilityToggleableFilter;
