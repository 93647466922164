import { reportInfo } from "@/common/application/debug";
import { IconButton } from "@/component-library/components/buttons/icon-button/IconButton";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import {
    singleRangeInputFieldStyle,
    singleRangeInputFieldValueStyle,
} from "@/component-library/components/user-input/range-input-fields/_SingleRangeInputField.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React, { useCallback } from "react";

interface _SingleRangeInputFieldProps {
    value: number;
    onValueChange?: (value: number) => void;
    formatValue?: (value: number) => string;

    min: number;
    max: number;
    step: number;
}

const _SingleRangeInputField: React.FC<_SingleRangeInputFieldProps> = ({
    onValueChange,
    formatValue,
    value,
    min,
    max,
    step,
}) => {
    const changeBy = useCallback(
        (by: number) => {
            {
                const newValue = value + by;
                const cappedValue = Math.min(max, Math.max(min, newValue));
                reportInfo(`Capped value: ${cappedValue}`);
                onValueChange?.(cappedValue);
            }
        },
        [max, min, onValueChange, value]
    );

    return (
        <Flex
            direction="row"
            className={clsx(singleRangeInputFieldStyle)}
            alignItems="center"
            justifyContent="space-evenly"
            gap={Magnitudes.spacingInRem.xxxs}
        >
            <IconButton
                label={"Decrease value"}
                variant="filledSmall"
                onClick={() => changeBy(-step)}
                style={assignInlineVars({
                    padding: `${Magnitudes.spacingInRem.xxxs}rem ${Magnitudes.spacingInRem.xxxs}rem`,
                })}
            >
                <FontAwesomeIcon icon={faMinus} />
            </IconButton>

            <div className={clsx(singleRangeInputFieldValueStyle)}>
                {formatValue?.(value) ?? value}
            </div>
            <IconButton
                label={"Decrease value"}
                variant="filledSmall"
                onClick={() => changeBy(step)}
                style={assignInlineVars({
                    padding: `${Magnitudes.spacingInRem.xxxs}rem ${Magnitudes.spacingInRem.xxxs}rem`,
                })}
            >
                <FontAwesomeIcon icon={faPlus} />
            </IconButton>
        </Flex>
    );
};

export default _SingleRangeInputField;
