import { Magnitudes } from "@/component-library/constants/Magnitudes";
import gsap from "gsap";
import { useEffect } from "react";

export const useSharedTimeline = (
    vars: gsap.TimelineVars = {
        defaults: {
            duration: Magnitudes.durationsInS.s,
            ease: "easeOut",
        },
    }
) => {
    const timeline = gsap.timeline(vars);

    useEffect(() => {
        return () => {
            timeline.revert();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return timeline;
};
