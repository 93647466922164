"use client";

import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import AppBarFilter from "@/features/filtering/components/AppBarFilter";
import { facilityFilter } from "@/features/filtering/filters/facility-filter/facilityFilter";
import FacilityFilterAppBarPopover from "@/features/filtering/filters/facility-filter/FacilityFilterAppBarPopover";
import { useFacilitiesFilter } from "@/features/filtering/filters/facility-filter/useFacilitiesFilter";
import { faBuildingUser } from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface FacilityFilterAppBarComponentProps {}

const FacilityFilterAppBarComponent: React.FC<
    FacilityFilterAppBarComponentProps
> = () => {
    const { setRequiredFacilities, requiredFacilities } = useFacilitiesFilter();

    return (
        <AppBarFilter
            filterId={facilityFilter.id}
            isActive={(requiredFacilities?.length ?? 0) > 0}
            onRemove={() => {
                void setRequiredFacilities(null);
            }}
            popover={<FacilityFilterAppBarPopover />}
            removePadding
        >
            <FontAwesomeIcon icon={faBuildingUser} />
            {requiredFacilities?.length
                ? requiredFacilities.length > 1
                    ? `${requiredFacilities.length} facilities`
                    : `${requiredFacilities.length} facility`
                : "Facilities"}
        </AppBarFilter>
    );
};

export default FacilityFilterAppBarComponent;
