import { prettyPrintObjectToString } from "@/common/application/prettyPrintObjectToString";
import { FailedToParseDataAccordingToSchemaError } from "@/common/domain/errors/development/FailedToParseDataAccordingToSchemaError";
import { FilteringConfig } from "@/configs/discover/FilteringConfig";
import { NetworkEndpoints } from "@/configs/NetworkEndpoints";
import { WezooSWRConfig } from "@/configs/WezooSWRConfig";
import { NetworkRequestError } from "@/features/backend/domain/errors/NetworkRequestError";
import { fetcher } from "@/features/backend/fetcher";
import {
    FacilityGroup,
    TFacilityGroup,
} from "@/features/resource/domain/entities/FacilityGroup";
import {
    FacilitySchema,
    TFacilitySchema,
} from "@/features/resource/domain/entities/FacilitySchema";
import { GroupedFacilities } from "@/features/resource/domain/entities/GroupedFacilities";
import useSWR from "swr";

export type TFacilitiesAndFacilityGroups<
    TFacilityGroups extends TFacilityGroup[],
> = {
    allFacilities: TFacilitySchema[];
    facilityGroups: TFacilityGroups;
    facilitiesInGroups: GroupedFacilities;
};

export const useSearchOptions = <
    TFacilityGroups extends TFacilityGroup[],
>() => {
    return useSWR<TFacilitiesAndFacilityGroups<TFacilityGroups>>(
        NetworkEndpoints.searchOptions(),
        (
            path: string
        ): Promise<TFacilitiesAndFacilityGroups<TFacilityGroups>> =>
            fetcher(
                path,
                undefined,
                undefined,
                async (
                    response
                ): Promise<TFacilitiesAndFacilityGroups<TFacilityGroups>> => {
                    if (response.status !== 200) {
                        throw NetworkRequestError({
                            userFacingMessage:
                                "Could not get available room facilities and location services",
                            statusCode: response.status,
                        });
                    }
                    const data = await response.json();

                    // ##########
                    // Parse facilities
                    const facilitiesRawData: any[] = [];
                    for (const facilityCategory of FilteringConfig.facilityCategories) {
                        facilitiesRawData.push(...data[facilityCategory]);
                    }

                    const facilitiesParseResult =
                        FacilitySchema.array().safeParse(facilitiesRawData);
                    if (!facilitiesParseResult.success) {
                        throw FailedToParseDataAccordingToSchemaError({
                            actualStructure:
                                prettyPrintObjectToString(facilitiesRawData),
                            schemaStructure: FacilitySchema.array(),
                            message:
                                "Failed to make sense of the available facilities",
                            wrappedError: prettyPrintObjectToString(
                                facilitiesParseResult.error
                            ),
                        });
                    }

                    const facilities = facilitiesParseResult.data;

                    // ##########
                    // Parse facility groups
                    const facilityGroupsParseResult =
                        FacilityGroup.array().safeParse(data["facilityGroups"]);
                    if (!facilityGroupsParseResult.success) {
                        throw FailedToParseDataAccordingToSchemaError({
                            actualStructure: prettyPrintObjectToString(
                                data["facilityGroups"]
                            ),
                            schemaStructure: FacilityGroup.array(),
                            message: `Failed to make sense of the available facility groups`,
                            wrappedError: prettyPrintObjectToString(
                                facilityGroupsParseResult.error
                            ),
                        });
                    }

                    const facilityGroups = facilityGroupsParseResult.data;

                    // ##########
                    // Add facilities to their corresponding groups
                    const facilitiesPerGroup: {
                        [key in string]: TFacilitySchema[];
                    } = {};

                    for (const facility of facilities) {
                        const facilityGroup = facilityGroups.find(
                            (group) => group.slug === facility.group
                        );

                        if (facilityGroup && facility.group) {
                            if (
                                !Object.keys(facilitiesPerGroup).includes(
                                    facilityGroup.slug
                                )
                            ) {
                                facilitiesPerGroup[facilityGroup.slug] = [
                                    facility,
                                ];
                            } else {
                                facilitiesPerGroup[facilityGroup.slug].push(
                                    facility
                                );
                            }
                        }
                    }

                    // Create an array to preserve order of groups from backend response
                    const facilitiesInGroups: GroupedFacilities = [];
                    for (const facilityGroup of facilityGroups) {
                        if (
                            Object.keys(facilitiesPerGroup).includes(
                                facilityGroup.slug
                            )
                        ) {
                            facilitiesInGroups.push({
                                group: facilityGroup,
                                facilities:
                                    facilitiesPerGroup[facilityGroup.slug],
                            });
                        }
                    }

                    return {
                        allFacilities: facilities,
                        facilityGroups: facilityGroups as TFacilityGroups,
                        facilitiesInGroups: facilitiesInGroups,
                    };
                }
            ),
        {
            ...WezooSWRConfig.defaultSWRConfig,
            refreshInterval: 0,
        }
    );
};
