import { z } from "zod";

export const PricingCalculationSchema = z.object({
    price: z.number(),
    fee: z.number(),
    totalPrice: z.number(),
    totalPriceIncludingTax: z.number(),
    currency: z.object({
        code: z.string(),
    }),
    intervalLength: z.enum(["HOUR", "DAY"]),
});
export type TPricingCalculationSchema = z.infer<
    typeof PricingCalculationSchema
>;

export const AvailabilitySchema = z.object({
    startMinutes: z.number(),
    endMinutes: z.number(),
    startDateTime: z.string(),
    endDateTime: z.string(),
    blockStart: z.string(),
    blockEnd: z.string(),
    available: z.number(),
    priceCalculation: PricingCalculationSchema,
});
export type TAvailabilitySchema = z.infer<typeof AvailabilitySchema>;
