"use client";

import { useApplyableParameterStore } from "@/app/(main)/discover/[[...params]]/(application)/useApplyableParameterStore";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import NumberSpinner, {
    NumberSpinnerHandle,
} from "@/component-library/components/user-input/number-spinner/NumberSpinner";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import AppBarFilter from "@/features/filtering/components/AppBarFilter";
import { capacityFilter } from "@/features/filtering/filters/capacity-filter/CapacityFilter";
import { capacityFilterQueryParam } from "@/features/filtering/filters/capacity-filter/capacityFilterQueryParam";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { parseAsInteger, useQueryState } from "nuqs";
import React, { useEffect, useRef, useState } from "react";

const CapacityFilterAppBarComponent: React.FC = () => {
    const { setParam } = useApplyableParameterStore();
    const [minCapacityQueryParam] = useQueryState(
        capacityFilterQueryParam,
        parseAsInteger
    );

    const [minCapacity, setMinCapacity] = useState<number | null>(
        minCapacityQueryParam
    );

    useEffect(() => {
        setParam(
            capacityFilterQueryParam,
            minCapacity?.toString() || undefined
        );
    }, [minCapacity, setParam]);

    const numberSpinnerRef = useRef<NumberSpinnerHandle | null>(null);

    useEffect(() => {
        if (!numberSpinnerRef.current) {
            return;
        }

        numberSpinnerRef.current.updateValue(minCapacity?.toString() ?? "");
    });

    return (
        <AppBarFilter
            filterId={capacityFilter.id}
            isActive={!!minCapacity}
            removePadding={true}
            popover={
                <div
                    style={assignInlineVars({
                        padding: `${Magnitudes.spacingInRem.s}rem`,
                    })}
                >
                    <NumberSpinner
                        ref={numberSpinnerRef}
                        name={"capacity-filter-value"}
                        defaultValue={minCapacity ?? 0}
                        value={minCapacity ?? 0}
                        min={0}
                        max={100}
                        step={1}
                        onValueChange={(newValue) => {
                            void setMinCapacity(() => newValue ?? 0);
                        }}
                    />
                </div>
            }
            onRemove={() => {
                void setMinCapacity(null);
            }}
        >
            <FontAwesomeIcon icon={faPeopleGroup} />
            <div
                style={assignInlineVars({
                    fontSize: `${Magnitudes.fontInRem.m}rem`,
                })}
            >
                {minCapacity
                    ? minCapacity > 1
                        ? `${minCapacity} visitors`
                        : `${minCapacity} visitor`
                    : "Who’s coming"}
            </div>
        </AppBarFilter>
    );
};

export default CapacityFilterAppBarComponent;
