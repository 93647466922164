import { TViewBoundsSchema } from "@/common/domain/entities/locations/ViewBoundsSchema";
import { hasValue } from "@/common/utilities/hasValue";
import { THostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/HostLocationWithWorkspaceGroupsSchema";

/**
 * Check if value is within bounds.
 * @param min - inclusive
 * @param max - inclusive
 * @param value
 */
const isInBounds = ({
    min,
    max,
    value,
}: {
    min: number;
    max: number;
    value: number;
}) => {
    return value >= min && value <= max;
};

interface GetLocationsInViewProps {
    locations: THostLocationWithWorkspaceGroupsSchema[];
    bounds: TViewBoundsSchema | null | undefined;
}

export const getLocationsInView = ({
    locations,
    bounds,
}: GetLocationsInViewProps) => {
    if (!hasValue(bounds)) {
        return locations;
    }

    return locations.filter(
        (location) =>
            isInBounds({
                value: location.location.latitude,
                min: bounds.minLatitude,
                max: bounds.maxLatitude,
            }) &&
            isInBounds({
                value: location.location.longitude,
                min: bounds.minLongitude,
                max: bounds.maxLongitude,
            })
    );
};
