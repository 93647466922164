export const clampAndSnapValueToStepSize = (
    value: string,
    options: {
        min: number;
        max: number;
        step: number;
    }
) => {
    const valueAsNumber = parseInt(value);

    if (isNaN(valueAsNumber)) {
        return null;
    }

    return (
        Math.round(
            Math.min(options.max, Math.max(options.min, valueAsNumber)) /
                options.step
        ) * options.step
    )
        .toString()
        .padStart(2, "0");
};
