import { z } from "zod";

export const OpeningHourSchema = z.object({
    day_of_week: z.number(),
    start_time: z.date({
        coerce: true,
    }),
    end_time: z.date({
        coerce: true,
    }),
});
export type TOpeningHourSchema = z.infer<typeof OpeningHourSchema>;
