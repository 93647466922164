import { WezooError } from "@/common/domain/errors/_base/WezooError";
import { _WezooErrorBuilder } from "@/common/domain/errors/_base/WezooErrorBuilder";
import { z } from "zod";

export const buildErrorWithPropsAndPayload = <
    TPayload extends z.ZodType,
    TProps extends object | undefined = undefined,
>({
    errorName,
    builder,
    build,
}: {
    errorName: string;
    builder: new () => _WezooErrorBuilder<z.infer<TPayload>>;
    payload: TPayload;
    build: (
        builder: InstanceType<typeof _WezooErrorBuilder<z.infer<TPayload>>>,
        props: TProps
    ) => InstanceType<typeof _WezooErrorBuilder<z.infer<TPayload>>>;
}) => {
    const result = (props: TProps, triggeredBy?: string) => {
        const configuredError = build(new builder(), props);

        return configuredError.__callBuild(errorName, triggeredBy);
    };

    Object.defineProperty(result, "name", {
        value: errorName,
        writable: false,
    });

    return result as (
        props: TProps,
        triggeredBy?: string
    ) => WezooError<z.infer<TPayload>>;
};

export const buildError = ({
    errorName,
    builder,
    build,
}: {
    errorName: string;
    builder: new () => _WezooErrorBuilder;
    build: (
        builder: InstanceType<typeof _WezooErrorBuilder<undefined>>
    ) => InstanceType<typeof _WezooErrorBuilder<undefined>>;
}) => {
    const result = buildErrorWithPropsAndPayload({
        errorName,
        builder,
        build,
        payload: z.undefined(),
    });

    Object.defineProperty(result, "name", {
        value: errorName,
        writable: false,
    });

    return result as (triggeredBy?: string) => WezooError;
};

export const buildErrorWithProps = <TProps extends object>({
    errorName,
    builder,
    build,
}: {
    errorName: string;
    builder: new () => _WezooErrorBuilder;
    build: (
        builder: InstanceType<typeof _WezooErrorBuilder<undefined>>,
        props: TProps
    ) => InstanceType<typeof _WezooErrorBuilder<undefined>>;
}) => {
    const result = buildErrorWithPropsAndPayload({
        errorName,
        builder,
        build,
        payload: z.undefined(),
    });

    Object.defineProperty(result, "name", {
        value: errorName,
        writable: false,
    });

    return result as (props: TProps, triggeredBy?: string) => WezooError;
};
