import { FilteringConfig } from "@/configs/discover/FilteringConfig";
import { Filter } from "@/features/filtering/domain/entities/Filter";
import { facilitiesFilteringStore } from "@/features/filtering/filters/facility-filter/facilitiesFilteringStore";
import {
    TFacilityFilterLocationLevelMetadataDataSchema,
    TFacilityFilterWorkspaceGroupLevelMetadataDataSchema,
} from "@/features/filtering/filters/facility-filter/facility-filter-schemas";
import FacilityFilterAppBarComponent from "@/features/filtering/filters/facility-filter/FacilityFilterAppBarComponent";
import { facilityFilterId } from "@/features/filtering/filters/facility-filter/facilityFilterId";
import { TMinimalFilterableHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/MinimalFilterableHostLocationWithWorkspaceGroupsSchema";

export const facilityFilter: Filter<
    TFacilityFilterLocationLevelMetadataDataSchema,
    TFacilityFilterWorkspaceGroupLevelMetadataDataSchema,
    typeof facilityFilterId
> = {
    id: facilityFilterId,
    urlSyncedComponent: <FacilityFilterAppBarComponent />,
    filterLocally: {
        async filterWorkspaceGroup({
            workspaceGroup,
        }: {
            location: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema;
            workspaceGroup: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema["workspace_groups"][0];
            queryParams: Record<string, string>;
        }): Promise<{
            isMatch: boolean;
            filterMetadata: TFacilityFilterWorkspaceGroupLevelMetadataDataSchema;
        }> {
            const requiredFacilities =
                facilitiesFilteringStore.getState().required || [];

            const requiredAmenities = requiredFacilities.filter(
                (facility) =>
                    facility.type === FilteringConfig.facilityCategory.amenity
            );

            const availableAmenities = workspaceGroup.amenities;

            const missingAmenities = requiredAmenities.filter(
                (requiredAmenity) =>
                    !availableAmenities.some(
                        (availableAmenity) =>
                            requiredAmenity.slug === availableAmenity.slug
                    )
            );

            return {
                isMatch: missingAmenities.length === 0,
                filterMetadata: {
                    missingAmenities,
                },
            };
        },
        filterLocation({
            location,
            workspaceGroupsWithMetadata,
        }: {
            location: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema;
            workspaceGroupsWithMetadata: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema["workspace_groups"];
            queryParams: Record<string, string>;
        }): {
            isMatch: boolean;
            filterMetadata: TFacilityFilterLocationLevelMetadataDataSchema;
        } {
            const requiredFacilities =
                facilitiesFilteringStore.getState().required || [];
            const requiredServices = requiredFacilities.filter(
                (facility) =>
                    facility.type === FilteringConfig.facilityCategory.service
            );

            const availableServices = location.location.services;

            const missingServices = requiredServices.filter(
                (requiredService) =>
                    !availableServices.some(
                        (availableService) =>
                            requiredService.slug === availableService.slug
                    )
            );

            return {
                isMatch:
                    missingServices.length === 0 &&
                    workspaceGroupsWithMetadata.some((group) => group.isMatch),
                filterMetadata: {
                    missingServices,
                },
            };
        },
    },
};
