export class FilteringConfig {
    static readonly updateDelayAfterFiltersChange = 400;
    static readonly filterRefreshInterval = 20000;

    static readonly facilityCategories = ["services", "amenities"];
    static readonly facilityCategory = {
        services: "services",
        amenities: "amenities",
        service: "service",
        amenity: "amenity",
    };

    private constructor() {}
}
