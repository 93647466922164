import { WezooError } from "@/common/domain/errors/_base/WezooError";
import { _WezooErrorBuilder } from "@/common/domain/errors/_base/WezooErrorBuilder";
import { assert } from "ts-essentials";

/**
 * For user facing errors, the user-facing message **needs to be set**.
 */
export class UserFacingErrorBuilder<
    TPayload = undefined,
> extends _WezooErrorBuilder<TPayload> {
    __errorType: string = "user-facing";

    build(errorName: string, triggeredBy?: string): WezooError<TPayload> {
        assert(
            !!this.__userFacingErrorMessage ||
                (this.__userFacingErrorMessage?.trim().length ?? 0) > 0,
            "You have to set user facing error message for the error!"
        );

        return super.build(errorName, triggeredBy);
    }
}
