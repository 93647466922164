import { ignore } from "@/common/application/ignore";
import { hasValue } from "@/common/utilities/hasValue";
import { useValueRef } from "@/component-library/animation/useValueRef";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import {
    timeInputFieldSeparatorStyle,
    timeInputFieldStyle,
    timeInputFieldSubEntryWrapperStyle,
} from "@/component-library/components/user-input/time-input-field/TimeInputField.css";
import TimeNumberInput from "@/component-library/components/user-input/time-input-field/TimeNumberInput";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";

export interface TimeInputFieldHandle {
    setValuesTo(value: [number, number]): void;
}

interface TimeInputFieldProps {
    fieldId: string;

    defaultHourValue?: number;
    defaultMinuteValue?: number;

    hoursToReserveAtEnd?: number;
    hoursToReserveAtStart?: number;

    disableTyping?: boolean;

    onChange?: (newValue: [number, number] | null) => void;
}

const TimeInputField = forwardRef<TimeInputFieldHandle, TimeInputFieldProps>(
    (
        {
            fieldId,
            defaultHourValue = 9,
            defaultMinuteValue = 0,
            hoursToReserveAtEnd,
            hoursToReserveAtStart,
            disableTyping = false,
            onChange,
        },
        refIn
    ) => {
        const {
            ref: valueRef,
            set: setValue,
            state: valueState,
        } = useValueRef<[number | null, number | null]>([
            defaultHourValue,
            defaultMinuteValue,
        ]);

        useImperativeHandle(refIn, () => ({
            setValuesTo(value: [number, number]) {
                setValue(value);
            },
        }));

        useEffect(() => {
            ignore(valueState);

            const hours = valueRef.current[0];
            const minutes = valueRef.current[1];

            if (hasValue(hours) && hasValue(minutes)) {
                onChange?.([hours, minutes]);
            } else {
                onChange?.(null);
            }
        }, [onChange, valueRef, valueState]);

        return (
            <Flex
                direction="row"
                alignItems="center"
                justifyContent="stretch"
                gap={Magnitudes.spacingInRem.xxs}
                className={timeInputFieldStyle}
            >
                <div className={timeInputFieldSubEntryWrapperStyle}>
                    <TimeNumberInput
                        defaultValue={defaultHourValue}
                        value={valueRef.current[0] ?? undefined}
                        name={`${fieldId}-start-time-minutes`}
                        label="hours"
                        min={hoursToReserveAtStart ?? 0}
                        max={23 - (hoursToReserveAtEnd ?? 0)}
                        step={1}
                        wrapAround
                        disableTyping={disableTyping}
                        onChange={(newValue) => {
                            if (valueRef.current[0] === newValue) {
                                return;
                            }

                            setValue([newValue, valueRef.current[1]]);
                        }}
                    />
                </div>
                <div className={timeInputFieldSeparatorStyle}>:</div>
                <div className={timeInputFieldSubEntryWrapperStyle}>
                    <TimeNumberInput
                        defaultValue={defaultMinuteValue}
                        value={valueRef.current[1] ?? undefined}
                        name={`${fieldId}-end-time-minutes`}
                        label="minutes"
                        min={0}
                        max={30}
                        step={30}
                        wrapAround
                        disableTyping={disableTyping}
                        onChange={(newValue) => {
                            if (valueRef.current[1] === newValue) {
                                return;
                            }

                            setValue([valueRef.current[0], newValue]);
                        }}
                    />
                </div>
            </Flex>
        );
    }
);
TimeInputField.displayName = "TimeInputField";

export default TimeInputField;
