export default ["EUR", "USD", "AUD", "GBP", "CHF", "DKK", "SEK", "NOK"];
export const maxFilterPrice: Record<string, number> = {
    EUR: 150,
    USD: 200,
    AUD: 400,
    GBP: 150,
    CHF: 200,
    DKK: 1500,
    SEK: 1500,
    NOK: 1500,
};

export const filterStepSize: Record<string, number> = {
    EUR: 10,
    USD: 10,
    AUD: 20,
    GBP: 10,
    CHF: 10,
    DKK: 100,
    SEK: 100,
    NOK: 100,
};
