import { capacityFilter } from "@/features/filtering/filters/capacity-filter/CapacityFilter";
import { dateTimeFilter } from "@/features/filtering/filters/date-time-filter/dateTimeFilter";
import { facilityFilter } from "@/features/filtering/filters/facility-filter/facilityFilter";
import { pricingFilter } from "@/features/filtering/filters/pricing-filter/pricingFilter";
import { resourceTypeFilter } from "@/features/filtering/filters/resource-type-filter/resourceTypeFilter";
import { TResourceSchema } from "@/features/resource/domain/entities/ResourceSchema";
import { hotdeskResource } from "@/features/resource/resource-types/hotdesk/hotdeskResource";
import { meetingRoomResource } from "@/features/resource/resource-types/meeting-room/meetingRoomResource";
import {
    TWezooResourceTypeSchema,
    WezooResourceTypeSchema,
} from "@/features/resource/resource-types/shared/WezooResourceType";
import { DeepReadonly } from "ts-essentials";
import { Filter } from "./filtering/domain/entities/Filter";

/**
 * Some additional resource types were created for testing purposes. These can
 * be removed here so that typechecking and autocomplete ignore them in the
 * IDE.
 */
const { ...implementedResources } = WezooResourceTypeSchema.enum;

type ResourcesList = DeepReadonly<{
    [key in keyof typeof implementedResources]: TResourceSchema;
}>;

export class WezooResourceData {
    /**
     * Resources are wrapped with the [DeepReadonly] type which makes the
     * objects immutable, and thus won't be accidentally modified, as these
     * objects should be static throughout the application runtime. **Add all
     * implemented resources here.**
     * @private
     */
    private static readonly _resources: ResourcesList = {
        ROOM: meetingRoomResource,
        HOTDESK: hotdeskResource,
    };

    private constructor() {}

    private static _allResources = Object.values(this._resources);

    static get allResources(): TResourceSchema[] {
        return this._allResources;
    }

    /**
     * All the resources that Wezoo web-platform offers and supports.
     */
    static getResource(resourceType: TWezooResourceTypeSchema) {
        // Resources are accessed through the getter, making it obvious to the
        // user that they are not meant to be modified.

        return this._resources[resourceType as keyof ResourcesList];
    }

    static getResourceByWorkspaceGroupType(workspaceGroupType: string) {
        return this.allResources.find(
            (resource) =>
                resource.metadata.workspaceGroupType === workspaceGroupType
        );
    }

    /**
     * Finds a matching resource for a given url slug.
     * @param urlSlug or undefined if no matching resource is found.
     */
    static getResourceForUrlSlug(urlSlug: string | undefined) {
        return this.allResources.find(
            (resource) => resource.metadata.urlSlug === urlSlug
        );
    }
}

export const AvailableFilters = [
    resourceTypeFilter,
    dateTimeFilter,
    capacityFilter,
    facilityFilter,
    pricingFilter,
] as Filter<any, any, any>[];
