import {
    separatorStyle,
    separatorStyleVariantRecipe,
} from "@/component-library/components/layout/separator/SeparatorStyles.css";
import { stylex } from "@/component-library/utilities/stylex";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React, { HTMLAttributes, ReactNode } from "react";

interface SeparatorProps extends HTMLAttributes<HTMLDivElement> {
    children?: ReactNode;
    color?: string;
    variant?: "unStyled" | "light" | "verticalLight";
}

const Separator: React.FC<SeparatorProps> = ({
    children,
    className,
    color,
    style,
    variant = "unStyled",
    ...restProps
}) => {
    return (
        <div
            className={clsx(
                separatorStyle,
                separatorStyleVariantRecipe({ variant }),
                className
            )}
            style={stylex(
                assignInlineVars({
                    backgroundColor: color,
                }),
                style
            )}
            {...restProps}
        >
            {children}
        </div>
    );
};

export default Separator;
