import { DateTimeFilterLocationLevelMetadataDataSchema } from "@/features/filtering/filters/date-time-filter/date-time-filter-schemas";
import { dateTimeFilterId } from "@/features/filtering/filters/date-time-filter/dateTimeFilterId";
import { FacilityFilterLocationLevelMetadataDataSchema } from "@/features/filtering/filters/facility-filter/facility-filter-schemas";
import { facilityFilterId } from "@/features/filtering/filters/facility-filter/facilityFilterId";
import { DiscountsSchema } from "@/features/host-locations/domain/entities/DiscountsSchema";
import { OpeningHourSchema } from "@/features/host-locations/domain/entities/OpeningHourSchema";
import { RatingsSchema } from "@/features/host-locations/domain/entities/RatingsSchema";
import { AddressSchema } from "@/features/host-locations/domain/entities/schemas/AddressSchema";
import { CurrencySchema } from "@/features/host-locations/domain/entities/schemas/CurrencySchema";
import { HostLocationPricingInformationSchema } from "@/features/host-locations/domain/entities/schemas/HostLocationPricingInformationSchema";
import { OperatorSchema } from "@/features/host-locations/domain/entities/schemas/operator/OperatorSchema";
import { PictureSchema } from "@/features/host-locations/domain/entities/schemas/PictureSchema";
import { FacilitySchema } from "@/features/resource/domain/entities/FacilitySchema";
import { z } from "zod";

export const LocationLevelFilterMetadata = z.object({
    // Filters can add metadata to the
    // `filterData` field, under a property
    // with the same name as the filter's id.
    // Components can access this information
    // to adjust how they display their
    // contents. All properties directly under
    // `filterData` should be optional, because
    // when filters are run in sequentially,
    // the metadata is progressively added by
    // each filter. Thus, until all applicable
    // filters have been run, the `filterData`
    // will have fields that are undefined.
    [facilityFilterId]:
        FacilityFilterLocationLevelMetadataDataSchema.optional(),
    [dateTimeFilterId]:
        DateTimeFilterLocationLevelMetadataDataSchema.optional(),
});

export type TLocationLevelFilterMetadata = z.infer<
    typeof LocationLevelFilterMetadata
>;

export const HostLocationGeneralInformationSchema = z.object({
    location: z.object({
        id: z.string(),
        slug: z.string(),
        name: z.string(),
        currency: CurrencySchema,
        description: z.string().nullable(),
        content: z.string().nullable(),
        address: AddressSchema,
        latitude: z.number(),
        longitude: z.number(),
        pictures: PictureSchema.array(),
        services: FacilitySchema.array(),
        operator: OperatorSchema.optional(),
        pricingInformation: HostLocationPricingInformationSchema.optional(),
        ratings: RatingsSchema.array().optional(),
        opening_hours: OpeningHourSchema.array(),
        tz: z.string(),
        discounts: DiscountsSchema.array(),
    }),
    // ########
    // Filtering

    // Indicates if the location is a
    // match, i.e. if the location
    // characteristics fulfill all
    // filter requirements. Does
    // **NOT** consider if there are
    // matching workspace groups.
    isMatch: z.boolean().default(true),
    filterMetadata: LocationLevelFilterMetadata.optional(),
});
export type THostLocationGeneralInformationSchema = z.infer<
    typeof HostLocationGeneralInformationSchema
>;
