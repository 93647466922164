import { z } from "zod";
import { StringToDateSchema } from "@/common/domain/entities/typing/StringToDateSchema";

export const AcceptedBookingParamsSchema = z
    .object({
        resource: z.string(),
    })
    .passthrough();
export type TAcceptedBookingParamsSchema = z.infer<
    typeof AcceptedBookingParamsSchema
>;

export const BookingPropertiesSchema = z.object({
    workspaceGroupId: z.string(),
    startTime: StringToDateSchema,
    endTime: StringToDateSchema,
});
export type TBookingPropertiesSchema = z.infer<typeof BookingPropertiesSchema>;

export interface ResourceUtilities {
    params: typeof AcceptedBookingParamsSchema;
    properties: typeof BookingPropertiesSchema;
}
