export const filterProps = <TProps extends object>(
    props: TProps,
    filterFn: (prop: string) => boolean
) => {
    const filteredProps: Partial<TProps> = {};
    for (const prop in props) {
        if (filterFn(prop)) {
            filteredProps[prop] = props[prop];
        }
    }

    return filteredProps;
};
