"use client";

import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { stylex } from "@/component-library/utilities/stylex";
import { useSharedTimeline } from "@/component-library/utilities/useSharedTimeline";
import { useGSAP } from "@gsap/react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import React, {
    FC,
    ReactNode,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

interface AnimatedFadeProps extends React.HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    isShowing: boolean | boolean[];
    durationInS?: number;
    delayInS?: number;
    keepAlwaysMounted?: boolean;
    fadeIn?: boolean;
    onShowChangeAnimationCompleted?: (isShowing: boolean) => void;
}

/**
 *
 * @param children - Content to fade in / out depending on the value of [isShowing].
 * @param isShowing - When an array, the children are shown only if all elements are true.
 * @param keepAlwaysMounted - When true, the children are mounted even when not showing; when
 * false, the children are unmounted when not showing.
 * @param durationInS - Duration of the animation in seconds.
 * @param fadeIn - When true, if [isShowing] is true on mount, fade in the content. When false,
 * @param style
 * @param restProps
 * @constructor
 */
const AnimatedFade: FC<AnimatedFadeProps> = ({
    children,
    isShowing,
    keepAlwaysMounted = false,
    durationInS,
    delayInS,
    fadeIn = false,
    style,
    onShowChangeAnimationCompleted,
    ...restProps
}) => {
    const contentRef = useRef<HTMLDivElement | null>(null);

    const [isMounted, setIsMounted] = useState(() => !fadeIn);

    const isActuallyShowing = useMemo(() => {
        const newValue = Array.isArray(isShowing)
            ? isShowing.filter((variable) => !variable).length === 0
            : isShowing;

        if (newValue) {
            setIsMounted(true);
        }

        return newValue;
    }, [isShowing]);

    const timeline = useSharedTimeline({
        defaults: {
            duration: durationInS ?? Magnitudes.durationsInS.m,
            delay: delayInS ?? 0,
            ease: "easeOut",
        },
    });

    useLayoutEffect(() => {
        if (fadeIn) {
            setIsMounted(isActuallyShowing);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useGSAP(() => {
        if (!isMounted) {
            return;
        }

        if (isActuallyShowing) {
            timeline.to(contentRef.current, {
                opacity: 1,
                onComplete: () => {
                    onShowChangeAnimationCompleted?.(true);
                },
            });
        } else {
            timeline.to(contentRef.current, {
                opacity: 0,
                onComplete: () => {
                    setIsMounted(false);
                    onShowChangeAnimationCompleted?.(false);
                },
            });
        }
    }, [isActuallyShowing, isMounted, contentRef.current]);

    return (
        (isMounted || keepAlwaysMounted) && (
            <div
                ref={contentRef}
                style={stylex(
                    assignInlineVars({
                        opacity: `0`,
                    }),
                    style
                )}
                {...restProps}
            >
                {children}
            </div>
        )
    );
};

export default AnimatedFade;
