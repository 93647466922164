/**
 * Converts a date to DD/MM/YYYY format, including leading zeros.
 * @param date
 * @param separator defaults to `-`.
 */
export const toDDMMYYYY = (date: Date, separator?: string) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const dayString = day < 10 ? `0${day}` : `${day}`;
    const monthString = month < 10 ? `0${month}` : `${month}`;

    separator = separator ?? "-";

    return `${dayString}${separator}${monthString}${separator}${year}`;
};
