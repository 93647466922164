import { StringToDateSchema } from "@/common/domain/entities/typing/StringToDateSchema";

import {
    AcceptedBookingParamsSchema,
    BookingPropertiesSchema,
} from "@/features/resource/domain/entities/ResourceUtilities";
import { z } from "zod";

/**
 * Parses the properties of a meeting room booking passed in the URL.
 */
export const HotdeskAcceptedBookingParamsSchema =
    AcceptedBookingParamsSchema.extend({
        start: z.string(),
        end: z.string(),
        products: z.array(z.string()).optional(),
    });
export type THotdeskAcceptedBookingParamsSchema = z.infer<
    typeof HotdeskAcceptedBookingParamsSchema
>;

/**
 * Parses processed meeting room booking URL params into booking properties.
 */
export const HotdeskBookingPropertiesSchema = BookingPropertiesSchema.extend({
    start: StringToDateSchema,
    end: StringToDateSchema,
});
export type THotdeskBookingPropertiesSchema = z.infer<
    typeof HotdeskBookingPropertiesSchema
>;
