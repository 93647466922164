import { FacilitySchema } from "@/features/resource/domain/entities/FacilitySchema";
import { z } from "zod";

export const ResourceTypeFilterLocationLevelMetadataDataSchema = z.object({
    missingServices: FacilitySchema.array(),
});
export type TResourceTypeFilterLocationLevelMetadataDataSchema = z.infer<
    typeof ResourceTypeFilterLocationLevelMetadataDataSchema
>;

export const ResourceTypeFilterWorkspaceGroupLevelMetadataDataSchema = z.object(
    {
        isMatchingResourceType: z.boolean(),
        isFilteredByResourceType: z.boolean(),
    }
);
export type TResourceTypeFilterWorkspaceGroupLevelMetadataDataSchema = z.infer<
    typeof ResourceTypeFilterWorkspaceGroupLevelMetadataDataSchema
>;
