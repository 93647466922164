import { SceneState } from "@/features/page-management/entities/SceneState";

export interface DiscoverUserSceneStateProps {
    currentlyHighlightedHostLocationId: string | null;
    currentlySelectedHostLocationId: string | null;
    currentlyOpenFilterId: string | null;
}

export class DiscoverSceneState extends SceneState<DiscoverUserSceneStateProps> {
    static readonly sceneKey = "DiscoverSceneState";

    currentlyHighlightedHostLocationId: string | null;
    currentlySelectedHostLocationId: string | null;
    currentlyOpenFilterId: string | null;

    constructor(props: DiscoverUserSceneStateProps) {
        super();

        this.currentlyHighlightedHostLocationId =
            props.currentlyHighlightedHostLocationId;
        this.currentlySelectedHostLocationId =
            props.currentlySelectedHostLocationId;
        this.currentlyOpenFilterId = props.currentlyOpenFilterId;
    }

    static initial(): DiscoverSceneState {
        return new DiscoverSceneState({
            currentlyHighlightedHostLocationId: null,
            currentlySelectedHostLocationId: null,
            currentlyOpenFilterId: null,
        });
    }

    copyWith(
        copyProps: Partial<DiscoverUserSceneStateProps>
    ): DiscoverSceneState {
        return new DiscoverSceneState({
            ...this,
            ...(copyProps as DiscoverUserSceneStateProps),
        });
    }
}
