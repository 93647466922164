import { TFacilityGroup } from "@/features/resource/domain/entities/FacilityGroup";
import { TFacilitySchema } from "@/features/resource/domain/entities/FacilitySchema";
import { GroupedFacilities } from "@/features/resource/domain/entities/GroupedFacilities";
import { create } from "zustand";

interface FacilitiesFilteringStoreState {
    stateIsReady: boolean;

    allAvailableFacilities: TFacilitySchema[] | null;

    facilityGroups: TFacilityGroup[] | null;
    facilitiesInGroups: GroupedFacilities | null;

    required: TFacilitySchema[] | null;
}

const initialState = (): FacilitiesFilteringStoreState => ({
    stateIsReady: false,

    allAvailableFacilities: null,

    facilityGroups: null,
    facilitiesInGroups: null,

    required: null,
});

interface FacilitiesFilteringStoreActions {
    setStateIsReady(isReady: boolean): void;

    setAllAvailableFacilitiesAndGroups(props: {
        allAvailableFacilities: TFacilitySchema[] | null;
        facilityGroups: TFacilityGroup[] | null;
        facilitiesInGroups: GroupedFacilities | null;
    }): void;

    setRequired(facilities: TFacilitySchema[] | null): void;
}

export const facilitiesFilteringStore = create<
    FacilitiesFilteringStoreState & FacilitiesFilteringStoreActions
>((set) => ({
    ...initialState(),

    setStateIsReady(isReady: boolean): void {
        set({ stateIsReady: isReady });
    },

    setAllAvailableFacilitiesAndGroups({
        facilitiesInGroups,
        allAvailableFacilities,
        facilityGroups,
    }): void {
        set({
            allAvailableFacilities,
            facilityGroups,
            facilitiesInGroups,
        });
    },

    setRequired: (facilities) => set({ required: facilities }),
}));
