import { buildErrorWithProps } from "@/common/application/errors/error-builders";
import { prettyPrintObjectToString } from "@/common/application/prettyPrintObjectToString";
import { UserFacingErrorBuilder } from "@/common/domain/errors/error-types/UserFacingErrorBuilder";
import { AnalyticsMetadataKeys } from "@/features/analytics/domain/constants/AnalyticsMetadataKeys";

export const NetworkRequestError = buildErrorWithProps({
    errorName: "NetworkRequestError",
    builder: UserFacingErrorBuilder,
    build: (
        builder,
        props: {
            wrappedError?: Error;
            statusCode?: number;
            userFacingMessage?: string;
            data?: any;
        }
    ) =>
        builder
            .enableAnalytics()
            .setInternalMessage(
                `Network request failed (${
                    props.statusCode ?? "no status code"
                }). With error: ${prettyPrintObjectToString(
                    props.wrappedError ?? "No error provided."
                )}`
            )
            .setUserFacingErrorMessage(
                props.userFacingMessage ??
                    props.wrappedError?.message ??
                    "Something went wrong while processing a network request. Please, make sure" +
                        " you are connected to the internet, and try again."
            )
            .addMetadata({
                statusCode: props.statusCode,
                [AnalyticsMetadataKeys.wrappedError]: prettyPrintObjectToString(
                    props.wrappedError
                )
                    ? prettyPrintObjectToString(props.wrappedError)
                    : undefined,
                [AnalyticsMetadataKeys.userFacingErrorMessage]:
                    props.userFacingMessage,
                [AnalyticsMetadataKeys.data]: prettyPrintObjectToString(
                    props.data
                ),
            }),
});
