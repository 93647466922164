type ValueConstructor<TValue> = (...args: any[]) => TValue;

/**
 * Lazy load delays the creation of the value until it's accessed for the first
 * time. After this, the value is cached. The value is not shared across
 * LazyLoad instances with the same [valueConstructor] function, unless the
 * result itself is a singleton. Adapted from
 * https://www.codementor.io/@agustinchiappeberrini/lazy-evaluation-and-javascript-a5m7g8gs3
 */
export const Lazy = <TValue>(
    valueConstructor: ValueConstructor<TValue>
): ((...args: any[]) => TValue) => {
    const constructor = valueConstructor;
    let value: TValue;
    let constructed = false;

    return function (this: unknown, ...args: any[]) {
        // Don't construct value again, if has been already.
        if (constructed) {
            return value;
        }

        value = constructor.apply(this, args) as TValue;
        constructed = true;
        return value;
    };
};
export type TLazy<TValue> = typeof Lazy<TValue>;
export type TLazyValue<TValue> = ReturnType<typeof Lazy<TValue>>;
