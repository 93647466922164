"use client";

import { useDiscoverMapContext } from "@/app/(main)/discover/[[...params]]/(components)/DiscoverMapProvider";
import { hasValue } from "@/common/utilities/hasValue";
import { asConvertedCurrency } from "@/common/utilities/math/asConvertedCurrency";
import { asCurrency } from "@/common/utilities/math/asCurrency";
import AnimatedFade from "@/component-library/animation/components/AnimatedFade";
import {
    filterStepSize,
    maxFilterPrice,
} from "@/component-library/components/currency/converted-currency/supportedCurrencies";
import CatchErrorsAndDisplayMessage from "@/component-library/components/feedback/user-facing-error-message-display/CatchErrorsAndDisplayMessage";
import CurrencySelect from "@/component-library/components/user-input/currency-select/CurrencySelect";
import RangeInputFields from "@/component-library/components/user-input/range-input-fields/RangeInputFields";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import { stylex } from "@/component-library/utilities/stylex";
import {
    pricingFilterCurrency,
    pricingFilterQueryEndKey,
    pricingFilterQueryStartKey,
} from "@/features/filtering/filters/pricing-filter/pricing-filte-query-keys";
import {
    currencySelectionContainer,
    pricingFilterPopoverSliderStyle,
    pricingFilterPopoverSliderWrapperStyle,
    pricingFilterThumbStyle,
} from "@/features/filtering/filters/pricing-filter/PricingFilterPopover.css";
import { TMinimalFilterableHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/MinimalFilterableHostLocationWithWorkspaceGroupsSchema";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import DineroFactory, { Currency } from "dinero.js";
import { useParams } from "next/navigation";
import { parseAsInteger, useQueryState } from "nuqs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactSlider from "react-slider";
import { Bar, BarChart, ResponsiveContainer, XAxis } from "recharts";
import { useDebounceCallback } from "usehooks-ts";

interface PricingFilterPopoverProps {}

const _PricingFilterSideBarComponent: React.FC<
    PricingFilterPopoverProps
> = () => {
    const [bucketCounts, setBucketCounts] = useState<
        { x: number; y: number }[]
    >([]);

    const [minimumPrice, setMinimumPrice] = useQueryState(
        pricingFilterQueryStartKey,
        parseAsInteger
    );
    const [maximumPrice, setMaximumPrice] = useQueryState(
        pricingFilterQueryEndKey,
        parseAsInteger
    );
    const [currency, setCurrency] = useQueryState(pricingFilterCurrency);
    const maxValue = useMemo(
        () => (currency ? maxFilterPrice[currency] : maxFilterPrice["EUR"]),
        [currency]
    );
    const stepSize = useMemo(
        () => (currency ? filterStepSize[currency] : filterStepSize["EUR"]),
        [currency]
    );
    const maxDineroValue = useMemo(
        () =>
            DineroFactory({
                amount: maxValue * 100,
                currency: (currency || "EUR") as Currency,
            }),
        [currency, maxValue]
    );

    const { hostLocationId } = useParams();

    const { hostLocations, hostLocationError: error } = useDiscoverMapContext();
    const singleLocation = useMemo(() => {
        return hostLocations?.find((location) => {
            return location.location.slug === hostLocationId;
        });
    }, [hostLocations, hostLocationId]);

    const data = useMemo(() => {
        if (!hostLocations && !hostLocationId) {
            return [];
        }

        let locationsToConsider: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema[] =
            [];
        if (hostLocationId && singleLocation) {
            locationsToConsider = [singleLocation];
        } else {
            locationsToConsider = hostLocations ?? [];
        }

        const result: {
            price: number;
            currencyCode: string;
        }[] = [];

        locationsToConsider.forEach((location) => {
            location.workspace_groups.forEach((group) => {
                if (
                    hasValue(group.pricingInformation?.lowestPrice.totalPrice)
                ) {
                    result.push({
                        price: group.pricingInformation!.lowestPrice.totalPrice,
                        currencyCode: location.location.currency.code,
                    });
                }
            });
        });

        return result;
    }, [hostLocationId, hostLocations, singleLocation]);

    useEffect(() => {
        void (async () => {
            const result: { [key: number]: number } = {};
            const convertedPrices = await Promise.all(
                data.map(async ({ price, currencyCode }) => {
                    return await asConvertedCurrency(
                        price,
                        currencyCode,
                        currency || "EUR"
                    );
                })
            );

            convertedPrices.forEach((price) => {
                if (price.getAmount() >= maxDineroValue.getAmount()) {
                    result[maxValue] = (result[maxValue] ?? 0) + 1;
                } else {
                    // https://stackoverflow.com/questions/1684202/how-to-round-an-integer-up-or-down-to-the-nearest-10-using-javascript
                    const priceToRoundedNearestTen =
                        Math.floor(price.toUnit() / stepSize) * stepSize;
                    result[priceToRoundedNearestTen] =
                        (result[priceToRoundedNearestTen] ?? 0) + 1;
                }
            });

            setBucketCounts(
                Object.entries(result).map(([bucketStart, count]) => {
                    return { x: Number(bucketStart), y: count };
                })
            );
        })();
    }, [data, maxValue, maxDineroValue, stepSize, currency]);

    const [newValues, setNewValues] = useState<{
        minimumPrice: number;
        highestPrice: number;
    }>({
        minimumPrice: minimumPrice ?? 0,
        highestPrice: maximumPrice ?? maxValue,
    });

    // Reset the pricing limits when the currency changes
    useEffect(() => {
        setNewValues({
            minimumPrice: 0,
            highestPrice: maxValue,
        });
        void setMinimumPrice(null);
        void setMaximumPrice(null);
    }, [currency, setMinimumPrice, setMaximumPrice, setNewValues, maxValue]);

    const isActive = useMemo(() => {
        return (
            (hasValue(newValues.minimumPrice) && newValues.minimumPrice > 0) ||
            (hasValue(newValues.highestPrice) &&
                newValues.highestPrice < maxValue)
        );
    }, [newValues.highestPrice, newValues.minimumPrice, maxValue]);

    useEffect(() => {
        setNewValues({
            minimumPrice: minimumPrice ?? 0,
            highestPrice: maximumPrice ?? maxValue,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const autoUpdateOnNewValuesChanged = useCallback(
        (values: typeof newValues) => {
            void setMinimumPrice(values.minimumPrice);
            void setMaximumPrice(values.highestPrice);
        },
        []
    );

    const debouncedAutoUpdateOnNewValuesChanged = useDebounceCallback(
        autoUpdateOnNewValuesChanged,
        250
    );

    useEffect(() => {
        debouncedAutoUpdateOnNewValuesChanged(newValues);
    }, [debouncedAutoUpdateOnNewValuesChanged, newValues]);

    if (error) {
        throw error;
    }

    return (
        <AnimatedFade
            isShowing={true}
            fadeIn
            durationInS={Magnitudes.durationsInS.m}
            delayInS={Magnitudes.durationsInS.s}
        >
            <div
                style={assignInlineVars({
                    position: "relative",
                    padding: `${Magnitudes.spacingInRem.l}rem`,
                })}
            >
                <h3>Price</h3>
                <div
                    style={assignInlineVars({
                        position: "relative",
                        height: `140px`,
                        overflow: `hidden`,
                    })}
                >
                    <ResponsiveContainer
                        width={"100%"}
                        height={"100%"}
                        minWidth={"100%"}
                        minHeight={"140px"}
                    >
                        <BarChart data={bucketCounts}>
                            <XAxis
                                min={0}
                                max={maxValue}
                                domain={[0, maxValue]}
                                type="number"
                                dataKey="x"
                                tickLine={false}
                                ticks={[0, 150]}
                                strokeOpacity={1}
                                padding={{
                                    left: 10,
                                    right: 10,
                                }}
                                tick={(props: any) => {
                                    const { index, x, y, payload } = props;

                                    return (
                                        <g>
                                            <g>
                                                <text
                                                    x={
                                                        x -
                                                        (index == 1 ? 10 : 5)
                                                    }
                                                    y={y}
                                                    visibility={"hidden"}
                                                >
                                                    {payload.value}
                                                </text>
                                            </g>
                                        </g>
                                    );
                                }}
                            />
                            <Bar
                                type="monotone"
                                dataKey="y"
                                stroke="none"
                                fill={theme.colors.charts.histogramBarColor}
                                by={stepSize}
                                barSize={19}
                                radius={[
                                    Magnitudes.radiiInPx.xxs,
                                    Magnitudes.radiiInPx.xxs,
                                    0,
                                    0,
                                ]}
                            />
                            {/*<Tooltip />*/}
                        </BarChart>
                    </ResponsiveContainer>
                    <div className={pricingFilterPopoverSliderWrapperStyle}>
                        <ReactSlider
                            key={"pricing-filter-popover-slider"}
                            onChange={(value) => {
                                setNewValues({
                                    minimumPrice: value[0],
                                    highestPrice: value[1],
                                });
                            }}
                            className={clsx(pricingFilterPopoverSliderStyle)}
                            thumbClassName={clsx(pricingFilterThumbStyle)}
                            step={stepSize}
                            value={[
                                newValues.minimumPrice,
                                newValues.highestPrice,
                            ]}
                            min={0}
                            max={maxValue}
                            ariaLabel={["Lower thumb", "Upper thumb"]}
                            ariaValuetext={(state) =>
                                `Thumb value ${state.valueNow}`
                            }
                            renderThumb={({ key, ...restProps }) => (
                                <div
                                    key={key}
                                    {...restProps}
                                    style={stylex(
                                        restProps.style,
                                        assignInlineVars({
                                            backgroundColor: isActive
                                                ? theme.colors.semantic
                                                      .interactive.default
                                                : theme.colors.charts
                                                      .sliderDisabledColor,

                                            transition: `all ${Magnitudes.durationsInS.xs}s ease-out`,
                                        })
                                    )}
                                />
                            )}
                            pearling
                            minDistance={stepSize}
                            renderTrack={({ key, ...restProps }, state) => (
                                <div
                                    key={key}
                                    {...restProps}
                                    style={stylex(
                                        restProps.style,
                                        assignInlineVars({
                                            top: `2px`,
                                            bottom: `2px`,

                                            background: `${
                                                isActive
                                                    ? state.index === 1
                                                        ? theme.colors.charts
                                                              .rangeColor
                                                        : theme.colors.charts
                                                              .sliderColor
                                                    : theme.colors.charts
                                                          .sliderDisabledColor
                                            }`,

                                            borderRadius: `${Magnitudes.radiiInPx.xs}px`,

                                            transition: `all ${Magnitudes.durationsInS.xs}s ease-out`,
                                        })
                                    )}
                                />
                            )}
                        />
                    </div>
                </div>
                <RangeInputFields
                    onValuesChange={({ begin, end }) => {
                        setNewValues({
                            minimumPrice: begin,
                            highestPrice: end,
                        });
                    }}
                    values={{
                        begin: newValues.minimumPrice,
                        end: newValues.highestPrice,
                    }}
                    min={0}
                    max={maxValue}
                    formatBeginValue={(value) =>
                        asCurrency(value * 100, currency || "EUR").toFormat(
                            "$0"
                        )
                    }
                    step={stepSize}
                    formatEndValue={(value) =>
                        !hasValue(value)
                            ? ""
                            : asCurrency(
                                  value * 100,
                                  currency || "EUR"
                              ).toFormat("$0") + (value === maxValue ? "+" : "")
                    }
                />
                <div className={currencySelectionContainer}>
                    <CurrencySelect
                        onChange={(event) => {
                            void setCurrency(event.currentTarget.value);
                        }}
                        name={"currency"}
                        value={currency || "EUR"}
                        currencies={[
                            {
                                code: "EUR",
                                name: "Euro",
                                icon: "🇪🇺",
                            },
                            {
                                code: "USD",
                                name: "United States Dollar",
                                icon: "🇺🇸",
                            },
                            {
                                code: "GBP",
                                name: "British Pound",
                                icon: "🇬🇧",
                            },
                            {
                                code: "SEK",
                                name: "Svensk krona",
                                icon: "🇸🇪",
                            },
                            {
                                code: "DKK",
                                name: "Dansk krone",
                                icon: "🇩🇰",
                            },
                            {
                                code: "NOK",
                                name: "Norsk krone",
                                icon: "🇳🇴",
                            },
                            {
                                code: "CHF",
                                name: "Schweizer frank",
                                icon: "🇨🇭",
                            },
                            {
                                code: "AUD",
                                name: "Australian Dollar",
                                icon: "🇦🇺",
                            },
                        ]}
                    />
                </div>
            </div>
        </AnimatedFade>
    );
};

export const PricingFilterSideBarComponent = () => {
    return (
        <CatchErrorsAndDisplayMessage>
            <_PricingFilterSideBarComponent />
        </CatchErrorsAndDisplayMessage>
    );
};

export default PricingFilterSideBarComponent;
