import moment from "moment";
import { createParser } from "nuqs";

export const parseAsTimezonelessDate = createParser({
    parse(queryValue) {
        return queryValue ? new Date(queryValue) : null;
    },
    // Serialize the date to a string in the
    // format "YYYY-MM-DDTHH:MM"
    serialize(value) {
        return moment(value).format("YYYY-MM-DDTHH:mm");
    },
});
