"use client";

import { hasValue } from "@/common/utilities/hasValue";
import { asCurrency } from "@/common/utilities/math/asCurrency";
import { maxFilterPrice } from "@/component-library/components/currency/converted-currency/supportedCurrencies";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import AppBarFilter from "@/features/filtering/components/AppBarFilter";
import {
    pricingFilterQueryEndKey,
    pricingFilterQueryStartKey,
    pricingFilterCurrency,
} from "@/features/filtering/filters/pricing-filter/pricing-filte-query-keys";
import { pricingFilterId } from "@/features/filtering/filters/pricing-filter/pricingFilterId";
import PricingFilterSideBarComponent from "@/features/filtering/filters/pricing-filter/PricingFilterSideBarComponent";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { parseAsInteger, useQueryState } from "nuqs";

import React, { useMemo } from "react";

interface PricingFilterComponentProps {}

const PricingFilterComponent: React.FC<PricingFilterComponentProps> = () => {
    const [minimumPrice, setMinimumPrice] = useQueryState(
        pricingFilterQueryStartKey,
        parseAsInteger
    );
    const [maximumPrice, setMaximumPrice] = useQueryState(
        pricingFilterQueryEndKey,
        parseAsInteger
    );
    const [currency] = useQueryState(pricingFilterCurrency);
    const maxValue = useMemo(
        () => (currency ? maxFilterPrice[currency] : maxFilterPrice["EUR"]),
        [currency]
    );

    const isActive = useMemo(() => {
        return (
            (hasValue(minimumPrice) && minimumPrice > 0) ||
            (hasValue(maximumPrice) && maximumPrice < maxValue)
        );
    }, [maximumPrice, minimumPrice, maxValue]);

    const maxPriceLabel = useMemo(() => {
        const maxPrice = maximumPrice ?? maxValue;

        const formattedPrice = asCurrency(
            maxPrice * 100,
            currency || "EUR"
        ).toFormat("$0");
        if (maxPrice === maxValue) {
            return formattedPrice + "+";
        }

        return formattedPrice;
    }, [maximumPrice, maxValue, currency]);

    return (
        <AppBarFilter
            key={"pricing-filter-app-bar-component"}
            filterId={pricingFilterId}
            popover={
                <PricingFilterSideBarComponent key={"pricing-filter-popover"} />
            }
            isActive={isActive}
            onRemove={() =>
                void Promise.all([setMinimumPrice(null), setMaximumPrice(null)])
            }
        >
            <FontAwesomeIcon icon={faTag} />
            {isActive
                ? `${
                      asCurrency(
                          (minimumPrice ?? 0) * 100,
                          currency || "EUR"
                      ).toFormat("$0") +
                      "-" +
                      maxPriceLabel
                  } per workspace`
                : "Price per hour"}
        </AppBarFilter>
    );
};

export default PricingFilterComponent;
