import { hasValue } from "@/common/utilities/hasValue";
import { NetworkConnectionFailed } from "@/features/backend/domain/errors/NetworkConnectionFailed";
import { UnexpectedNetworkResponseError } from "@/features/backend/domain/errors/UnexpectedNetworkResponseError";
import contentType from "content-type";

/**
 * Fetcher for useSWR hooks.
 * @param url
 * @param params
 * @param config
 * @param responseHandler
 * @param fetcherOptions - By default, we expect JSON responses.
 */
export const fetcher = async <TReturnValue = Response>(
    url: string,
    params?: Record<string, string | number | undefined | (string | number)[]>,
    config?: RequestInit,
    responseHandler?: (response: Response) => Promise<TReturnValue>,
    fetcherOptions?: {
        expectedResponseContentType?: string[];
    }
): Promise<TReturnValue> => {
    const urlWithParams = new URL(url);
    if (params) {
        const queryParams: string[] = [];
        Object.entries(params).forEach(([key, value]) => {
            if (value === undefined) {
                return;
            }
            if (Array.isArray(value)) {
                const encodedValues = value.map((v) =>
                    encodeURIComponent(v.toString())
                );
                const joinedValues = encodedValues.join(",");
                queryParams.push(`${encodeURIComponent(key)}=${joinedValues}`);
            } else {
                queryParams.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`
                );
            }
        });
        const queryString = queryParams.join("&");
        urlWithParams.search = queryString;
    }

    let res: Response;
    try {
        res = await fetch(urlWithParams, config);
    } catch (error) {
        throw NetworkConnectionFailed({
            connectionErrorMessageForInternalProcessing:
                error instanceof Error
                    ? error.message
                    : `Fetch to "${urlWithParams.toString()}" failed.`,
        });
    }

    const actualContentTypeHeader = res.headers.get("Content-Type");
    if (!hasValue(actualContentTypeHeader)) {
        throw UnexpectedNetworkResponseError({
            reason: `No Content-Type header in response from ${urlWithParams}`,
        });
    }

    const actualContentType = contentType.parse(actualContentTypeHeader).type;
    const expectedResponseContentType =
        fetcherOptions?.expectedResponseContentType?.map((t) =>
            t.toLowerCase()
        ) ?? ["application/json"];

    if (
        !expectedResponseContentType.includes(actualContentType.toLowerCase())
    ) {
        throw UnexpectedNetworkResponseError({
            reason: `Unexpected Content-Type header in response from ${urlWithParams}: ${actualContentType} not in expected types: ${expectedResponseContentType}`,
        });
    }

    return responseHandler
        ? responseHandler(res)
        : Promise.resolve(res as TReturnValue);
};
