import { Lazy } from "@/common/domain/entities/Lazy";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { TResourceDataBuilder } from "@/features/resource/domain/entities/ResourceDataSchema";
import { TReadOnlyResourceSchema } from "@/features/resource/domain/entities/ResourceSchema";
import {
    HotdeskAcceptedBookingParamsSchema,
    HotdeskBookingPropertiesSchema,
} from "@/features/resource/resource-types/hotdesk/domain/entities/HotdeskBookingSchemas";
import { WezooResourceTypeSchema } from "@/features/resource/resource-types/shared/WezooResourceType";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";

/**
 * Hotdesk resource data is created on demand when accessing the [data]
 * property of [hotdeskResourceData].
 */
const hotdeskResourceData: TResourceDataBuilder = Lazy(() => {
    return {
        resourceType: WezooResourceTypeSchema.enum.HOTDESK,
        title: "Hot Desk",
        utilitySchemas: {
            params: HotdeskAcceptedBookingParamsSchema,
            properties: HotdeskBookingPropertiesSchema,
        },
    };
});

/**
 * Represents the hotdesk resource offered by Wezoo.
 */
export const hotdeskResource: TReadOnlyResourceSchema = {
    getData: hotdeskResourceData,
    metadata: {
        type: WezooResourceTypeSchema.enum.HOTDESK,
        urlSlug: "hotdesk",
        name: "hot desk",
        namePlural: "hot desks",
        icon: <FontAwesomeIcon icon={faDesktop} />,
        workspaceGroupType: "HOTDESK",
    },
};
