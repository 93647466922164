import { generateErrorMailtoLink } from "@/app/(application)/generateErrorMailtoLink";
import { WezooError } from "@/common/domain/errors/_base/WezooError";
import Button from "@/component-library/components/buttons/button/Button";
import { userFacingErrorMessageDisplayStyle } from "@/component-library/components/feedback/user-facing-error-message-display/UserFacingErrorMessageDisplay.css";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import React, { FC } from "react";
import { FallbackProps } from "react-error-boundary";

const ErrorBoundaryMessage: FC<FallbackProps> = (props) => {
    return (
        <Flex
            direction="column"
            alignItems="stretch"
            gap={Magnitudes.spacingInRem.s}
            className={userFacingErrorMessageDisplayStyle}
        >
            {(props.error instanceof WezooError
                ? props.error.getUserFacingMessage()
                : props.error?.message) ??
                "Something went wrong, please try again."}

            <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={Magnitudes.spacingInRem.xxl}
            >
                <a
                    href={generateErrorMailtoLink(props.error)}
                    style={assignInlineVars({
                        alignSelf: `center`,
                    })}
                >
                    Share error by email
                </a>
                <Button
                    variant="outlined"
                    onClick={() => window.location.reload()}
                    style={assignInlineVars({
                        alignSelf: `center`,
                    })}
                >
                    Refresh page
                </Button>
            </Flex>
        </Flex>
    );
};

export default ErrorBoundaryMessage;
