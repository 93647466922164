import { buildErrorWithProps } from "@/common/application/errors/error-builders";
import { UserFacingErrorBuilder } from "@/common/domain/errors/error-types/UserFacingErrorBuilder";

export const UnexpectedNetworkResponseError = buildErrorWithProps({
    errorName: "UnexpectedNetworkResponseError",
    builder: UserFacingErrorBuilder,
    build: (
        builder,
        props: {
            reason: string;
            userFacingMessage?: string;
        }
    ) =>
        builder
            .enableAnalytics()
            .setInternalMessage(`Unexpected network response: ${props.reason}`)
            .setUserFacingErrorMessage(
                props.userFacingMessage ??
                    `Something went wrong communicating with Wezoo. Please, try again.`
            ),
});
