"use client";

import { useHostLocations } from "@/features/host-locations/application/useHostLocations";
import { TSimplifiedHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/SimplifiedHostLocationWithWorkspaceGroupsSchema";
import React, { createContext, useContext } from "react";

// Define the shape of the context data
interface DiscoverMapContextData {
    hostLocations: TSimplifiedHostLocationWithWorkspaceGroupsSchema[] | null;
    isLoading: boolean | undefined;
    hostLocationError: Error | null;
}

// Create the context with a default value of null
const DiscoverMapContext = createContext<DiscoverMapContextData | null>(null);

// Create a provider component
interface WorkspaceGroupsProviderProps {
    hostLocationId?: string;
    children: React.ReactNode;
}

export const DiscoverMapProvider: React.FC<WorkspaceGroupsProviderProps> = ({
    hostLocationId,
    children,
}) => {
    const {
        hostLocations,
        isFetching,
        error: hostLocationError,
    } = useHostLocations({
        hostLocationId,
    });

    return (
        <DiscoverMapContext.Provider
            value={{
                hostLocations,
                isLoading: isFetching, //isFetching,
                hostLocationError,
            }}
        >
            {children}
        </DiscoverMapContext.Provider>
    );
};

// Create a custom hook for consuming the context
export const useDiscoverMapContext = () => {
    const context = useContext(DiscoverMapContext);
    if (context === null) {
        throw new Error(
            "useWorkspaceGroups must be used within a DiscoverMapProvider"
        );
    }
    return context;
};
