import { create } from "zustand";

export const DEFAULT_INITIAL_ZOOM = 6;

export interface DiscoverMapStoreState {
    params: Record<string, string | undefined>;
}

const initialState = (): DiscoverMapStoreState => ({
    params: {},
});

export interface DiscoverMapStoreActions {
    setParam: (key: string, value?: string) => void;
    setParams: (params: Record<string, string>) => void;
}

export const useApplyableParameterStore = create<
    DiscoverMapStoreState & DiscoverMapStoreActions
>((set) => ({
    ...initialState(),
    setParam: (key, value) =>
        set((state) => ({
            params: {
                ...state.params,
                [key]: value,
            },
        })),
    setParams: (params) =>
        set(() => ({
            params: params,
        })),
}));
