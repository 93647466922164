"use client";

import { DiscoverSceneState } from "@/app/(main)/discover/(layout)/(application)/DiscoverSceneState";
import { IconButton } from "@/component-library/components/buttons/icon-button/IconButton";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import { redColor } from "@/component-library/themes/themes/lightTheme";
import { stylex } from "@/component-library/utilities/stylex";
import {
    appBarFilterContentStyle,
    appBarFilterPopoverStyle,
    appBarFilterStyle,
} from "@/features/filtering/components/AppBarFilter.css";
import { useSceneStateStore } from "@/features/page-management/application/useSceneStateStore";
import {
    autoUpdate,
    offset,
    shift,
    useClick,
    useFloating,
    useFocus,
    useInteractions,
    useRole,
} from "@floating-ui/react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useGSAP } from "@gsap/react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import gsap from "gsap";
import React, {
    CSSProperties,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useMediaQuery, useOnClickOutside } from "usehooks-ts";

interface AppBarFilterProps {
    filterId: string;
    children?: ReactNode;
    popover?: ReactNode;
    isActive?: boolean;
    onRemove?: () => void;

    widthBreakpointBelowWhichPopoverTakesFullWidth?: number;
    removePadding?: boolean;
}

const AppBarFilter: React.FC<AppBarFilterProps> = ({
    filterId,
    children,
    popover,
    isActive,
    onRemove,
    widthBreakpointBelowWhichPopoverTakesFullWidth = 380,
    removePadding = false,
}) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        strategy: "absolute",
        placement: "bottom-start",
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset(Magnitudes.spacingInRem.xxs), shift()],
        whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const focus = useFocus(context);

    const role = useRole(context, {
        role: "dialog",
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        focus,
        role,
    ]);

    const referenceProps = useMemo(
        () => getReferenceProps(),
        [getReferenceProps]
    );
    const floatingProps = useMemo(() => getFloatingProps(), [getFloatingProps]);

    const currentlyOpenFilterId = useSceneStateStore(
        (state) =>
            state.scenes[DiscoverSceneState.sceneKey].currentlyOpenFilterId
    );

    const updateSceneState = useSceneStateStore(
        (state) => state.updateSceneState
    );

    const isMobileView = useMediaQuery(
        `screen and (max-width: ${widthBreakpointBelowWhichPopoverTakesFullWidth}px)`
    );

    useEffect(() => {
        if (isOpen) {
            updateSceneState(DiscoverSceneState.sceneKey, {
                currentlyOpenFilterId: filterId,
            });
        } else {
            if (currentlyOpenFilterId === filterId) {
                updateSceneState(DiscoverSceneState.sceneKey, {
                    currentlyOpenFilterId: null,
                });
            }
        }
    });

    useEffect(() => {
        if (currentlyOpenFilterId === filterId) {
            setIsOpen(true);
        }
    }, [currentlyOpenFilterId, filterId]);

    useGSAP(() => {
        if (isOpen && !isMounted) {
            setIsMounted(true);
        }

        if (refs.floating.current) {
            gsap.to(refs.floating.current, {
                opacity: isOpen ? 1 : 0,
                duration: Magnitudes.durationsInS.m,
                ease: "easeOut",
                onComplete: () => {
                    if (!isOpen) {
                        setIsMounted(false);
                    }
                },
            });
        }
    }, [isOpen, isMounted, refs.floating]);

    const closeFilterPopover = useCallback(() => {
        setIsOpen(false);

        if (currentlyOpenFilterId === filterId) {
            updateSceneState(DiscoverSceneState.sceneKey, {
                currentlyOpenFilterId: null,
            });
        }
    }, [currentlyOpenFilterId, filterId, updateSceneState]);

    useOnClickOutside(wrapperRef, () => {
        closeFilterPopover();
    });

    return (
        <div
            ref={wrapperRef}
            className={clsx(appBarFilterStyle)}
            style={assignInlineVars({
                backgroundColor: isActive
                    ? theme.colors.semantic.interactive.default
                    : theme.colors.semantic.interactive.transparent,
                color: isActive
                    ? theme.colors.basic.white
                    : theme.colors.semantic.interactive.default,
            })}
        >
            <button
                ref={refs.setReference}
                {...referenceProps}
                className={clsx(
                    referenceProps["className"] as string,
                    appBarFilterContentStyle
                )}
                style={stylex(
                    referenceProps["style"] as CSSProperties,
                    assignInlineVars({
                        flex: `1 1 auto`,
                    })
                )}
                onFocus={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                {children}
            </button>

            {isActive && (
                <IconButton
                    variant="filledSmall"
                    label={`Remove filter`}
                    onClick={() => {
                        onRemove?.();
                    }}
                    accentColor={redColor}
                    style={assignInlineVars({
                        flex: `0 0 auto`,

                        marginRight: `${Magnitudes.spacingInRem.xs}rem`,
                    })}
                >
                    <FontAwesomeIcon title={`Remove filter`} icon={faXmark} />
                </IconButton>
            )}

            {isMounted && (
                <div
                    ref={refs.setFloating}
                    {...floatingProps}
                    className={clsx(
                        appBarFilterPopoverStyle,
                        floatingProps["className"] as string
                    )}
                    style={stylex(
                        floatingStyles,
                        floatingProps["style"] as CSSProperties,

                        removePadding &&
                            assignInlineVars({
                                padding: `0`,
                            }),
                        isMobileView &&
                            assignInlineVars({
                                width: "100%",

                                margin: `0`,
                            })
                    )}
                >
                    {popover}
                </div>
            )}
        </div>
    );
};

export default AppBarFilter;
