import { z } from "zod";

export const FacilitySchema = z.object({
    type: z.string(),
    slug: z.string(),
    display_name: z.string(),
    description: z.string(),
    icon: z.string(),
    group: z.string().optional(),
});
export type TFacilitySchema = z.infer<typeof FacilitySchema>;
