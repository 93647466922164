import { facilityFilterAppBarComponentPopoverStyle } from "@/features/filtering/filters/facility-filter/FacilityFilterAppBarComponent.css";
import FacilityFilterAppBarPopoverSkeleton from "@/features/filtering/filters/facility-filter/FacilityFilterAppBarPopoverSkeleton";
import FacilityToggleableFilter from "@/features/filtering/filters/facility-filter/FacilityToggleableFilter";
import { useFacilitiesFilter } from "@/features/filtering/filters/facility-filter/useFacilitiesFilter";
import { TFacilitySchema } from "@/features/resource/domain/entities/FacilitySchema";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import React, { Fragment, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";

interface FacilityFilterAppBarPopoverProps {}

const FacilityFilterAppBarPopover: React.FC<
    FacilityFilterAppBarPopoverProps
> = () => {
    const {
        setRequiredFacilities,
        requiredFacilities,
        facilitiesInGroups,
        facilityGroups,
        facilitiesStateIsReady,
    } = useFacilitiesFilter();

    const numberOfNonEmptyFacilityGroups = useMemo(() => {
        return facilityGroups?.reduce((accumulator, group) => {
            const dataForGroup = facilitiesInGroups?.find(
                (dataGroup) => dataGroup.group.slug === group.slug
            );
            accumulator += (dataForGroup?.facilities.length ?? 0) > 0 ? 1 : 0;

            return accumulator;
        }, 0);
    }, [facilitiesInGroups, facilityGroups]);

    const shouldWrap = useMediaQuery("screen and (max-width: 1100px)");

    if (!facilitiesStateIsReady) {
        return <FacilityFilterAppBarPopoverSkeleton />;
    }

    return (
        <div
            className={facilityFilterAppBarComponentPopoverStyle}
            style={assignInlineVars({
                gridTemplateColumns: shouldWrap
                    ? "1fr"
                    : `repeat(${
                          numberOfNonEmptyFacilityGroups ?? 0
                      }, min(320px, 80vw))`,
            })}
        >
            {facilitiesInGroups &&
                facilitiesInGroups.map((group) => {
                    if (group.facilities.length === 0) {
                        return <Fragment key={group.group.slug} />;
                    }

                    return (
                        <div key={group.group.slug}>
                            <h2>{group.group.display_name}</h2>
                            {group.facilities.map((facility) => {
                                const requiredFacilitiesOrEmpty =
                                    requiredFacilities ?? [];
                                return (
                                    <FacilityToggleableFilter
                                        key={facility.slug}
                                        facility={facility}
                                        isToggled={requiredFacilitiesOrEmpty.some(
                                            (requiredFacility) =>
                                                requiredFacility.slug ===
                                                facility.slug
                                        )}
                                        onToggle={(
                                            toggledFacility,
                                            isRequired
                                        ) => {
                                            let newRequiredFacilities: TFacilitySchema[] =
                                                [];

                                            if (isRequired) {
                                                /**
                                                 * When the filter is
                                                 * toggled on, we push
                                                 * it to the
                                                 * [newRequiredFacilities]
                                                 * which is used to
                                                 * update the store and
                                                 * the query params.
                                                 */
                                                newRequiredFacilities.push(
                                                    ...[
                                                        ...requiredFacilitiesOrEmpty,
                                                        toggledFacility,
                                                    ]
                                                );
                                            } else {
                                                /**
                                                 * If the user unchecked
                                                 * the
                                                 * filter, we remove any
                                                 * matching slugs from
                                                 * required facilities.
                                                 */
                                                newRequiredFacilities =
                                                    requiredFacilitiesOrEmpty.filter(
                                                        (requiredFacility) =>
                                                            requiredFacility.slug !==
                                                            toggledFacility.slug
                                                    );
                                            }

                                            // Update query params to
                                            // reflect new state
                                            void setRequiredFacilities(
                                                newRequiredFacilities.length > 0
                                                    ? newRequiredFacilities
                                                    : null
                                            );
                                        }}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
        </div>
    );
};

export default FacilityFilterAppBarPopover;
