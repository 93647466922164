import { hasValue } from "@/common/utilities/hasValue";
import { Filter } from "@/features/filtering/domain/entities/Filter";
import { TCapacityFilterWorkspaceGroupLevelMetadataDataSchema } from "@/features/filtering/filters/capacity-filter/capacity-filter-schemas";
import CapacityFilterAppBarComponent from "@/features/filtering/filters/capacity-filter/CapacityFilterAppBarComponent";
import { capacityFilterId } from "@/features/filtering/filters/capacity-filter/capacityFilterId";
import { capacityFilterQueryParam } from "@/features/filtering/filters/capacity-filter/capacityFilterQueryParam";
import { TMinimalFilterableHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/MinimalFilterableHostLocationWithWorkspaceGroupsSchema";

export const capacityFilter: Filter<
    undefined,
    TCapacityFilterWorkspaceGroupLevelMetadataDataSchema,
    typeof capacityFilterId
> = {
    id: capacityFilterId,
    applyableComponent: <CapacityFilterAppBarComponent />,
    filterLocally: {
        async filterWorkspaceGroup({
            location,
            workspaceGroup,
            queryParams,
        }: {
            location: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema;
            workspaceGroup: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema["workspace_groups"][0];
            queryParams: Record<string, string>;
        }): Promise<{
            isMatch: boolean;
            filterMetadata: TCapacityFilterWorkspaceGroupLevelMetadataDataSchema;
        }> {
            const hasCapacityRequirement: string | undefined =
                queryParams[capacityFilterQueryParam];
            let capacityRequirement = 0;
            if (
                hasValue(hasCapacityRequirement) &&
                hasCapacityRequirement.trim().length > 0
            ) {
                capacityRequirement = parseInt(
                    queryParams[capacityFilterQueryParam]
                );
            }

            const { type } = workspaceGroup;
            if (type === "HOTDESK") {
                // In case of hotdesks, we need to check if there are enough available.
                // If there are, all match the filter. If there aren't, none match the filter.
                const availableHotdesks = location.workspace_groups
                    .filter((group) => group.type === "HOTDESK")
                    .map((hotdesk) => hotdesk.workspaces)
                    .flat()
                    .map((workspace) => workspace.allocations)
                    .reduce((count, a) => count + a, 0);

                return {
                    isMatch: availableHotdesks >= capacityRequirement,
                    filterMetadata: {
                        hasEnoughCapacity:
                            availableHotdesks >= capacityRequirement,
                    },
                };
            }

            const hasEnoughCapacity =
                capacityRequirement <= workspaceGroup.max_occupants;

            return {
                isMatch: hasEnoughCapacity,
                filterMetadata: {
                    hasEnoughCapacity,
                },
            };
        },
        filterLocation({
            workspaceGroupsWithMetadata,
        }: {
            location: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema;
            workspaceGroupsWithMetadata: TMinimalFilterableHostLocationWithWorkspaceGroupsSchema["workspace_groups"];
            queryParams: Record<string, string>;
        }): { isMatch: boolean; filterMetadata: undefined } {
            return {
                isMatch: workspaceGroupsWithMetadata.some(
                    (group) => group.isMatch
                ),
                filterMetadata: undefined,
            };
        },
    },
};
