/**
 * Allows you to ignore return values from functions or variables, or
 * dependencies needed for refreshing the effect.
 * @param _toIgnore - what to ignore.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ignore = (..._toIgnore: unknown[]) => {
    /* Ignore is used to intentionally ignore return values from functions or
     * variables.
     */
};
